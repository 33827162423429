/* eslint-disable spaced-comment */
import { TOOLS_LOAD, TOOLS_LOAD_SUCCESS } from '../constants/ToolsTypes';
import Config from '../../Config';

export function requestTools() {
  return {
    type: TOOLS_LOAD
  };
}

export function receiveTools(data) {
  const thematiques = data[0].thematiques;
  const types = data[0].types;

  const tools = data[0].outils;
  let pagination = parseInt(data[0].config.pagination, 10);

  if (window.matchMedia('(max-width:768px)').matches) {
    pagination = parseInt(data[0].config.pagination_mobile, 10);
  }

  tools.forEach((tool) => {
    const currentThematique = thematiques.find(
      thematique => parseInt(thematique.tid, 10) === parseInt(tool.th, 10)
    );
    const currentType = types.find(
      type => parseInt(type.tid, 10) === parseInt(tool.ty, 10)
    );
    Object.assign(tool, {
      thematique: {
        id: currentThematique.tid,
        name: currentThematique.n
      },
      type: {
        id: currentType.tid,
        name: currentType.n
      },
    });
    if (!currentThematique.tools) {
      currentThematique.tools = [];
    }
    currentThematique.tools.push(tool.id);

    if (!currentType.tools) {
      currentType.tools = [];
    }
    currentType.tools.push(tool.id);
  });

  return {
    type: TOOLS_LOAD_SUCCESS,
    thematiques,
    types,
    tools,
    pagination
  };
}

export function fetchTools() {
  return dispatch => {
    dispatch(requestTools());
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(receiveTools(json));
      }
    };
    req.open('GET', Config.URL.TOOLS, true);
    req.send();

    return;
  };
}

// export function shouldRequest(state) {
//   // if (state.questions.isFetching) {
//   //   return false;
//   // } else if (state.questions.carousel.length !== 0) {
//   //   return false;
//   // }
//   return true;
// }

// export function requestToolsIfNeeded() {
//   return (dispatch, getState) => {
//     if (shouldRequest(getState())) {
//       return dispatch(fetchTools());
//     }
//   };
// }

import {
  EDITORIAL_LOAD,
  EDITORIAL_LOAD_SUCCESS,
  EDITORIAL_LOAD_ERROR,
} from '../constants/EditorialTypes';

const initState = {
  blocks: [],
  isFetching: false,
};

export function editorial(state = initState, action) {
  switch (action.type) {
    case EDITORIAL_LOAD: {
      return {
        ...state,
        isFetching: true
      };
    }
    case EDITORIAL_LOAD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        blocks: action.blocks,
      };
    }
    case EDITORIAL_LOAD_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    default:
      return state;
  }
}

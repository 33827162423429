import React from 'react';
import Cubic from 'svg-cubic';

import BaseComponent from '../../common/components/BaseComponent';
import { TweenMax } from '../../common/lib/gsap.js';

import {
  WIDTH, HEIGHT,
  CUBIC, CUBIC_OVER, CUBIC_OUT, CUBIC_CLICK,
  SIDE_LEFT, SIDE_RIGHT
} from '../constants/Animation';

const propTypes = {
  available: React.PropTypes.bool.isRequired,
  color: React.PropTypes.string.isRequired,
  isMainCarousel: React.PropTypes.bool.isRequired,
  onAnimationStarted: React.PropTypes.func.isRequired,
  onAnimationComplete: React.PropTypes.func.isRequired,
  panO: React.PropTypes.object,
  side: React.PropTypes.string.isRequired
};

class Arrow extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('onClickHandler', 'onMouseEnterHandler', 'onMouseLeaveHandler');

    this.state = {
      selected: false
    };
  }

  componentDidMount() {
    this.reset();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.launchSlide) {
      this.onClickHandler();
    }
  }

  render() {
    const { side, color, isMainCarousel, panO } = this.props;
    const { cubic, selected } = this.state;

    const viewBox = [0, 0, WIDTH, HEIGHT];
    const classes = ['arrow', side, color];

    if (selected) {
      classes.push('animated');
    }

    if (panO) {
      this.animateCubic(0.2, panO);
    }

    const onProps = {
      onClick: this.onClickHandler,
      ... isMainCarousel ? {
        onMouseEnter: this.onMouseEnterHandler,
        onMouseLeave: this.onMouseLeaveHandler
      } : {
        onTouchStart: this.onMouseEnterHandler,
        onTouchEnd: this.onMouseLeaveHandler
      }
    };

    return (
      <div
        className={ classes.join(' ') }
        ref="arrow"
        {...onProps}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={WIDTH}
          height={HEIGHT}
          viewBox={viewBox.join(' ')}
        >
          <path
            d={cubic ? cubic.path() : ' '}
            ref="path"
          />
        </svg>
        <span className="icon-chevron" ref="chevron">
          <span className="hidden">{`icon-${side}`}</span>
        </span>
      </div>
    );
  }

  animateChevron(duration, options) {
    const { chevron } = this.refs;
    const { available } = this.props;

    if (!available) {
      return;
    }

    TweenMax.to(chevron, duration, options);
  }

  // Animate svg path
  animateCubic(duration, options) {
    const { cubic } = this.state;
    const { available } = this.props;

    if (!available) {
      return;
    }

    Object.assign(options, {
      onUpdate: () => {
        this.setState({cubic});
      }
    });

    TweenMax.to(cubic, duration, options);
  }

  // On click, animate svg path, and arrow container
  onClickHandler() {
    const { arrow } = this.refs;
    const { available, onAnimationStarted, onAnimationComplete, side } = this.props;

    if (!available || !onAnimationStarted) {
      return;
    }

    this.setState({selected: true});

    this.animateCubic(0.3, CUBIC_CLICK);
    this.animateChevron(0.2, {opacity: 0});

    TweenMax.to(arrow, 0.35, {
      x: '0%',
      onStart: onAnimationStarted,
      onComplete: onAnimationComplete,
      onCompleteParams: [side === SIDE_LEFT ? -1 : 1]
    });
  }

  onMouseEnterHandler(event) {
    event.stopPropagation();
    const { side } = this.props;
    const chevronPosition = window.innerWidth < 768 ? '-20px' : '-40px';

    this.animateCubic(0.2, CUBIC_OVER);
    if (side === SIDE_LEFT) {
      this.animateChevron(0.2, {right: chevronPosition});
    } else {
      this.animateChevron(0.2, {left: chevronPosition});
    }
  }

  onMouseLeaveHandler(event) {
    event.stopPropagation();
    const { side } = this.props;
    const chevronPosition = window.innerWidth < 768 ? '-25px' : '-50px';

    this.animateCubic(0.7, CUBIC_OUT);
    if (side === SIDE_LEFT) {
      this.animateChevron(0.2, {right: chevronPosition});
    } else {
      this.animateChevron(0.2, {left: chevronPosition});
    }
  }

  // Reset arrow position
  reset() {
    const { side } = this.props;
    const { arrow, chevron } = this.refs;
    const chevronPosition = window.innerWidth < 768 ? '-25px' : '-50px';

    const options = {
      w: WIDTH,
      h: HEIGHT,
      d: side === SIDE_LEFT ? SIDE_RIGHT : SIDE_LEFT
    };

    this.setState({
      selected: false,
      cubic: new Cubic(Object.assign(options, CUBIC))
    });

    switch (side) {
      case SIDE_LEFT:
        TweenMax.set(chevron, {right: '-75px', opacity: 0});
        TweenMax.set(arrow, {x: '-100%'});
        TweenMax.set(arrow, {x: '-=100'});
        TweenMax.to(arrow, 0.5, {x: '+=100', delay: 0.2});
        TweenMax.to(chevron, 0.2, {right: chevronPosition, opacity: 1, delay: 0.7});
        break;
      case SIDE_RIGHT:
        TweenMax.set(chevron, {left: '-75px', opacity: 0});
        TweenMax.set(arrow, {x: '100%'});
        TweenMax.set(arrow, {x: '+=100'});
        TweenMax.to(arrow, 0.5, {x: '-=100', delay: 0.2});
        TweenMax.to(chevron, 0.2, {left: chevronPosition, opacity: 1, delay: 0.7});
        break;
    }
  }
}

Arrow.propTypes = propTypes;

export default Arrow;

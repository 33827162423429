import Cookies from 'cookies-js';

const COOKIE_AT = 'AT';

let ATInternet;
let tag;

export function sendTag(param, search) {
  if (!tag) { return; }

  const options = {
    name: param.split(' ').join('_'),
    type: 'action'
  };
  if (search) {
    options.search = search;
  }
  tag.click.send(options);
}

function initView() {
  jQuery('.footer-links a').click((event) => {
    const label = jQuery(event.target).text().toLowerCase();
    if (label.indexOf('brochure') !== -1) {
      sendTag('Footer_brochures');
    } else if (label.indexOf('presse') !== -1) {
      sendTag('Footer_espace_presse');
    }
  });

  jQuery('.footer-block a').click(() => {
    sendTag('Footer_version_simplifiee');
  });

  jQuery('#share-content a').click(() => {
    sendTag('Inscription_rnr');
  });

  jQuery('#share-content a').click(() => {
    sendTag('Inscription_rnr');
  });
}

function getStateAT() {
  const value = Cookies.get(COOKIE_AT) || 'true';
  return value === 'true';
}

function updateAtButton() {
  const active = getStateAT();
  if (active) {
    jQuery('#btn-at').attr('class', 'btn red');
    jQuery('#btn-at').html(`J'interdis les cookies d'analyse d'audience exempts de consentement`);
  } else {
    jQuery('#btn-at').attr('class', 'btn green');
    jQuery('#btn-at').html(`J'autorise les cookies d'analyse d'audience exempts de consentement`);
  }
}

function initButtonsAT() {
  updateAtButton();

  jQuery('#btn-at').click((event) => {
    event.preventDefault();
    event.stopPropagation();

    const state = getStateAT();
    if (tag && state) {
      tag.privacy.setVisitorOptout();
    }
    Cookies.set(COOKIE_AT, !state, { secure: true });
    updateAtButton();
  });
}

function tagCurrentPage() {
  if (!tag) { return; }

  tag.page.set({
    name: Drupal.settings.title
  });
  tag.dispatch();
}

export function initAT() {
  initButtonsAT();

  const state = getStateAT();
  if (state === false) {
    return;
  }

  jQuery.getScript('https://tag.aticdn.net/564794/smarttag.js')
    .done(() => {
      ATInternet = window.ATInternet;
      tag = ATInternet ? new ATInternet.Tracker.Tag() : null;

      if (tag) {
        const authority = 'cnil';
        const visitorMode = tarteaucitron.state.atinternet ? 'visitorMode' : 'exempt';
        tag.privacy.setVisitorMode(authority, visitorMode);

        tagCurrentPage();
        initView();
      }
    });
}

import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  data: React.PropTypes.object.isRequired,
};

class Card extends BaseComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    let cardCta;
    if (data.ctaText && data.ctaUrl) {
      cardCta = (
        <div className="city-card__cta"><a href={data.ctaUrl} className="btn position-btn city-card__btn">{ data.ctaText }</a></div>
      );
    }

    return (
      <div className="city-card">
        <h2 className="city-card__title">{ data.title }</h2>
        <div className="city-card__text">
          <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
        </div>
        { cardCta }
      </div>
    );
  }
}

Card.propTypes = propTypes;

export default Card;

import Isotope from 'isotope-layout';

let isotope;

function startIsotope() {
  if (Drupal.settings.isFront && !Drupal.settings.isRGAA) {
    isotope = new Isotope(document.getElementsByClassName('box-holder')[0], {
      itemSelector: '.box-wrap',
      masonry: {
        columnWidth: 0,
        gutter: 0
      },
      transitionDuration: '0s'
    });

    jQuery('.box a').click(event => {
      event.stopPropagation();
    });

    jQuery('.box').click(function onBoxClick() {
      jQuery(this).find('a')[0].click();
    });
  }
}

function updateIsotope() {
  if (!isotope) {
    return;
  }
  isotope.layout();
}

export {
  startIsotope,
  updateIsotope,
};

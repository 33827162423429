import Isotope from 'isotope-layout';

let isotope;
let element;

function startEditorialMosaic() {
  if (!Drupal.settings.isRGAA) {
    element = document.getElementsByClassName('view-display-id-block_don_du_vivant')[0];

    if (element) {
      isotope = new Isotope(element.querySelector('.box-holder'), {
        itemSelector: '.box-wrap',
        masonry: {
          columnWidth: 0,
          gutter: 0
        },
        transitionDuration: '0s'
      });
    }

    jQuery('.box a').click(event => {
      event.stopPropagation();
    });

    jQuery('.box').click(function onBoxClick() {
      jQuery(this).find('a')[0].click();
    });
  }
}

function updateEditorialMosaic() {
  if (!isotope) {
    return;
  }
  isotope.layout();
}

export {
  startEditorialMosaic,
  updateEditorialMosaic,
};

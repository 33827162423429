import {
  POPIN_CLOSE,
  POPIN_OPEN,
  POPIN_ANIMATION
} from '../constants/PopinTypes';

export function show(type, content, animateFrom) {
  document.body.classList.add('popin-state');
  return {
    type: POPIN_OPEN,
    payload: {
      type,
      content,
      animateFrom
    }
  };
}

export function hide() {
  document.body.classList.remove('popin-state');
  return {
    type: POPIN_CLOSE
  };
}

export function setAnim(animation) {
  return {
    type: POPIN_ANIMATION,
    payload: {
      animationType: animation
    }
  };
}

import { show } from '../common/redux/actions/SearchActions';
import store from '../common/redux/store';

import { sendTag } from './at-internet';

export default function bindSearchButton() {
  function dispatchSearch() {
    store.dispatch(show());
  }

  jQuery('#nav .search-btn').click((event) => {
    event.preventDefault();
    sendTag('Header_recherche');
    dispatchSearch();
  });

  jQuery('.intro-block .search-btn').click((event) => {
    event.preventDefault();
    sendTag('Page_question_bouton_poser_question');
    dispatchSearch();
  });

  jQuery('#main .search-btn').click((event) => {
    event.preventDefault();
    dispatchSearch();
  });
}

import React from 'react';
import { connect } from 'react-redux';
import MainComponent from '../common/components/MainComponent';
import { hide } from '../common/redux/actions/PopinActions';
import YouTube from 'react-youtube';

class Popin extends MainComponent {
  constructor(props) {
    super(props);
    this._bind('clickHandler', 'onImageLoaded', 'renderTranscript',
      'renderImage', 'createTranscriptMarkup');
    this.state = {
      hideClass: false,
      initialized: false,
      styles: {},
      imageLoaded: false
    };
  }

  render() {
    const { animateFrom, isOpen, type, content } = this.props;
    const { hideClass, imageLoaded } = this.state;
    const classNames = ['media-popin'];
    let renderedContent;

    if (isOpen) {
      if (!animateFrom || animateFrom && imageLoaded) {
        classNames.push('media-popin--open');
      }
    } else {
      classNames.push('media-popin--closed');
    }

    if (hideClass) {
      classNames.push('media-popin--hide');
    }

    if (type === 'transcript') {
      classNames.push('media-popin__transcript', 'transcription-page');
      renderedContent = this.renderTranscript(content);
    } else if (type === 'video') {
      classNames.push('media-popin__video zoom-video');
      renderedContent = this.renderVideo(content);
    } else if (type === 'image' || type === 'file') {
      classNames.push('media-popin__image zoom-page');
      renderedContent = this.renderImage(content);
    }

    return (
      <div className={ classNames.join(' ') } onClick={this.clickHandler}>
          { renderedContent }
      </div>
    );
  }

  getStyles(element, position = 'fixed', animation = false) {
    const { animateFrom } = this.props;
    const height = element === animateFrom ? element.height : this.getImageHeight();
    const width = element === animateFrom ? element.width : this.getImageWidth();

    return {
      position,
      top: element.getBoundingClientRect().top,
      left: element.getBoundingClientRect().left + (element.offsetWidth - width) / 2,
      height,
      width,
      transition: animation ? 'all 250ms ease-in' : 'none'
    };
  }

  getImageHeight() {
    const { animateFrom } = this.props;

    if (animateFrom) {
      if (animateFrom.height >= animateFrom.width) {
        return window.innerHeight - 200;
      }
    }

    return 'auto';
  }

  getImageWidth() {
    const { animateFrom } = this.props;
    let returnedValue = 'auto';

    if (animateFrom) {
      if (animateFrom.height < animateFrom.width) {
        if (window.matchMedia('(min-width:1200px)').matches) {
          returnedValue = 998;
        } else if (window.matchMedia('(min-width:992px)').matches) {
          returnedValue = 813;
        } else if (window.matchMedia('(min-width:768px)').matches) {
          returnedValue = 696;
        } else {
          returnedValue = window.innerWidth - 21 * 2;
        }
      } else {
        returnedValue = animateFrom.width * this.getImageHeight() / animateFrom.height;
      }
    }
    return returnedValue;
  }

  renderTranscript(content) {
    return (
        <div className="holder">
          <div className="top-wrap">
            <a className="close-btn" onClick={ this.clickHandler }>FERMER</a>
          </div>
          <div className="text" dangerouslySetInnerHTML={ this.createTranscriptMarkup(content) }>
          </div>
        </div>
    );
  }

  renderVideo(content) {
    const { styles } = this.state;

    const opts = {
      height: '390',
      width: '1200',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return (
      <div className="w1">
        <div className="zoom-video__wrap">
          <div className="top-wrap">
            <a className="zoom-video__close" onClick={ this.clickHandler }><span className="zoom-video__close-text">FERMER</span></a>
          </div>
          <div className="zoom-video__wrapper" ref="imagePopinHolder">
            <YouTube videoId={content} opts={opts}/>
          </div>
        </div>
      </div>
  );
  }

  renderImage(url) {
    const { styles } = this.state;

    return (
        <div className="w1">
          <div className="zoom">
            <div className="top-wrap">
              <a className="close-btn" onClick={ this.clickHandler }>FERMER</a>
            </div>
            <div className="img-holder" ref="imagePopinHolder">
                <img src={ url } style={styles} ref="imagePopin" onLoad={this.onImageLoaded}/>
            </div>
          </div>
        </div>
    );
  }

  onImageLoaded() {
    const { animateFrom } = this.props;
    const { initialized } = this.state;

    // If dom element where animate image from
    // First: Get style of clicked image
    // Second: Change style to default zoom image size and position, with animation
    // Thrid: Remove fixed position, for displaying scrollbar
    if (animateFrom && !initialized) {
      this.setState({
        initialized: true,
        styles: this.getStyles(animateFrom),
        imageLoaded: true
      });

      setTimeout(() => {
        animateFrom.classList.add('hide');
        const { imagePopinHolder } = this.refs;
        this.setState({
          styles: this.getStyles(imagePopinHolder, 'fixed', true)
        });
      }, 10);
    }
  }

  createTranscriptMarkup(content) {
    return {__html: content};
  }

  clickHandler() {
    const { animateFrom } = this.props;

    if (animateFrom) {
      const styles = {
        position: 'fixed',
        top: animateFrom.getBoundingClientRect().top,
        left: animateFrom.getBoundingClientRect().left,
        transition: 'all 250ms ease-out'
      };

      if (animateFrom.height >= animateFrom.width) {
        Object.assign(styles, { height: animateFrom.height });
      } else {
        Object.assign(styles, { width: animateFrom.width});
      }

      this.setState({
        styles
      });
    }

    setTimeout(() => {
      if (animateFrom) {
        animateFrom.classList.remove('hide');
      }
      this.props.dispatch(hide());
      this.setState({
        hideClass: false,
        initialized: false,
        imageLoaded: false
      });
    }, 250);
  }
}
export default connect((state) => {
  return {
    isOpen: state.popin.isOpen,
    type: state.popin.type,
    content: state.popin.content,
    animateFrom: state.popin.animateFrom
  };
})(Popin);

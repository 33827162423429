import React, { Component, PropTypes } from 'react';
import BaseComponent from '../../common/components/BaseComponent';

class Checkbox extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('toggleCheckboxChange');

    const { value, filters } = this.props;

    let test;
    if (filters && filters.length > 0) {
      test = filters.includes(parseInt(value, 10));
      this.setState({
        isChecked: test
      });
    }

    this.state = {
      isChecked: test || false,
    };
  }


  toggleCheckboxChange() {
    const { handleCheckboxChange, value } = this.props;
    const { isChecked } = this.state;
    const { checkbox } = this.refs;
    this.setState({
      isChecked: !isChecked
    });

    handleCheckboxChange(value);
  }

  render() {
    const { value, label, key, filters} = this.props;
    const { isChecked } = this.state;

    return (
      <div className={`tools-filters__checkbox${isChecked ? ' is-checked' : ''}`} ref="checkbox">
         <label htmlFor={key}>
          <input type="checkbox" id={key} name={key} value={value} onChange={this.toggleCheckboxChange} className="tools-filters__checkbox" checked={isChecked}/>
          {label}
         </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;

import {
  CLOSE_ALL,
  FILTER_CLOSE,
  FILTER_OPEN,
  SORTER_OPEN,
  SORTER_CLOSE
} from '../constants/FilterTypes.js';

const initState = {
  isFilterOpen: false,
  isSorterOpen: false,
};

export function filter(state = initState, action = null) {
  const { type } = action;
  switch (type) {
    case CLOSE_ALL:
      return {
        isFilterOpen: false,
        isSorterOpen: false,
      };
    case FILTER_OPEN:
      return {
        isFilterOpen: true,
        isSorterOpen: false,
      };
    case FILTER_CLOSE:
      return {
        ...state,
        isFilterOpen: false,
      };
    case SORTER_OPEN:
      return {
        isFilterOpen: false,
        isSorterOpen: true,
      };
    case SORTER_CLOSE:
      return {
        ...state,
        isSorterOpen: false,
      };
    default:
      return state;
  }
}

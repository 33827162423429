import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Search from '../../../search/Search';
import store from '../store';

import {
  SEARCH_HIDE,
  SEARCH_LOAD,
  SEARCH_LOAD_ERROR,
  SEARCH_LOAD_SUCCESS,
  SEARCH_SHOW
} from '../constants/SearchTypes';
import Config from '../../Config';

export function performSearch() {
  return {
    type: SEARCH_LOAD
  };
}

export function searchError() {
  return {
    type: SEARCH_LOAD_ERROR
  };
}

export function searchComplete(data) {
  const results = Array.from(data).map(question => parseInt(question, 10));
  return {
    type: SEARCH_LOAD_SUCCESS,
    results
  };
}

export function search(sentence) {
  return dispatch => {
    dispatch(performSearch());

    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(searchComplete(json));
      } else {
        dispatch(searchError());
      }
    };
    req.open('GET', Config.URL.SEARCH + sentence, true);
    req.send();

    return;
  };
}

export function show() {
  const searchDOM = document.getElementById('search-react');
  document.body.classList.add('questions-page');
  if (searchDOM) {
    ReactDOM.render(
      <Provider store={ store }>
        <Search />
      </Provider>,
      searchDOM);
  }

  return {
    type: SEARCH_SHOW
  };
}

export function hide() {
  const searchDOM = document.getElementById('search-react');
  document.body.classList.remove('questions-page');
  ReactDOM.unmountComponentAtNode(searchDOM);
  store.dispatch({
    type: SEARCH_HIDE
  });
}

export function searchHide() {
  return {
    type: SEARCH_HIDE
  };
}

import scriptjs from 'scriptjs';
import Cookies from 'cookies-js';

function isActive() {
  return Drupal.settings.ddo_campaign && Drupal.settings.isFront;
}

function hidePopin() {
  jQuery('body').removeClass('overlay-popin');
  jQuery('#home-popin').remove();
}

function setPopin() {
  jQuery('body').toggleClass('overlay-popin');
  const text = Drupal.settings.ddo_campaign.overlay_text.replace(/\n/g, '<br />');
  let content = `
    <div id="home-popin">
      <div class="content">
        <a class="close" href="#"></a>
        <p class="title">Information</p>`;

  if (Drupal.settings.ddo_campaign.overlay_url) {
    content += `<img src="${Drupal.settings.ddo_campaign.overlay_url}" />`;
  }
  if (Drupal.settings.ddo_campaign.overlay_text !== '') {
    content += `<p class="text">${text}</p>`;
  }
  content += `</div></div>`;
  jQuery('#wrapper')
    .append(content);

  const onResize = () => {
    if ((jQuery('#home-popin .content').innerHeight() + 100) > window.innerHeight) {
      jQuery('#home-popin').addClass('fixed');
    } else {
      jQuery('#home-popin').removeClass('fixed');
    }
  };

  jQuery('#home-popin img').load(onResize);
  jQuery(window).resize(onResize);

  jQuery('#home-popin').on('click', event => {
    if (event.target !== jQuery('#home-popin').get(0)) {
      return;
    }

    jQuery(window).off('resize', onResize);


    hidePopin();
  });

  jQuery('#home-popin .close').on('click', event => {
    event.stopPropagation();
    event.preventDefault();
    hidePopin();
  });

  if (Drupal.settings.ddo_campaign.overlay_link_title &&
      Drupal.settings.ddo_campaign.overlay_link_title !== '') {
    jQuery('#home-popin .content')
      .append(`
        <a href="${Drupal.settings.ddo_campaign.overlay_link_url}" class="btn">
          ${Drupal.settings.ddo_campaign.overlay_link_title}
        </a>
      `);
  }
}

function setVideoOverlay() {
  if (window.matchMedia('(max-width:768px)').matches) {
    return;
  }

  if (Cookies.get('disableOverlay')) {
    return;
  }

  let player;

  jQuery('body').toggleClass('overlay-page');

  if (Drupal.settings.ddo_campaign.overlay_type === 'video') {
    jQuery('#wrapper').append('<div id="youtube-overlay"></div>');

    scriptjs('https://www.youtube.com/iframe_api');

    window.onYouTubeIframeAPIReady = () => {
      player = new YT.Player('youtube-overlay', {
        videoId: Drupal.settings.ddo_campaign.overlay_url,
        playerVars: {
          showInfo: 0,
          rel: 0
        },
        events: {
          onReady: () => {
            player.mute();
            player.playVideo();
          }
        }
      });
    };
  } else {
    jQuery('body').css('backgroundImage', `url(${Drupal.settings.ddo_campaign.overlay_url})`);
  }

  jQuery('#header .holder')
    .append('<a href="/" class="js-overlay link">accéder au site</a>');
  if (Drupal.settings.ddo_campaign.overlay_link_title &&
      Drupal.settings.ddo_campaign.overlay_link_title !== '') {
    jQuery('#wrapper')
      .append(`<div class="js-overlay btn-holder">
        <a href="${Drupal.settings.ddo_campaign.overlay_link_url}" class="btn">
          ${Drupal.settings.ddo_campaign.overlay_link_title}
        </a></div>`);
  }


  Cookies.set('disableOverlay', true, { expires: 600 });
}

export default function addOverlay() {
  if (!isActive()) {
    return;
  }

  if (Drupal.settings.ddo_campaign.overlay_type === 'video') {
    setVideoOverlay();
  } else if (
    Drupal.settings.ddo_campaign.overlay_text !== '' ||
    Drupal.settings.ddo_campaign.overlay_url
  ) {
    setPopin();
  }
}

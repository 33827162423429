export default function updateShareMenuSelection() {
  if (window.matchMedia('(max-width:768px)').matches) {
    return;
  }

  const sharePosition = jQuery('#share-position');
  if (!sharePosition) {
    return;
  }

  function isOnScreen(element) {
    const elementOffsetTop = element.offset().top;
    const elementHeight = element.height();

    const screenScrollTop = jQuery(window).scrollTop();
    const screenHeight = jQuery(window).height();

    const scrollIsAboveElement = elementOffsetTop + elementHeight - screenScrollTop >= 0;
    const elementIsVisibleOnScreen = screenScrollTop + screenHeight - elementOffsetTop >= 0;

    return scrollIsAboveElement && elementIsVisibleOnScreen;
  }

  function disable() {
    jQuery('[data-target-id="share-position"]').removeClass('active');
    jQuery('[data-target-id="share-position"]').parent().removeClass('active');
  }

  function enable() {
    jQuery('[data-target-id="share-position"]').addClass('active');
    jQuery('[data-target-id="share-position"]').parent().addClass('active');
  }

  function checkOnScroll() {
    if (isOnScreen(sharePosition)) {
      enable();
    } else {
      disable();
    }
  }

  disable();

  jQuery(document).scroll(checkOnScroll);
}

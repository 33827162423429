import Glide from '@glidejs/glide';

export default function initToolsCarousel() {
  if (Drupal.settings.isRGAA || jQuery('.tools-module').length === 0) {
    return;
  }

  const mediaQuery = '(max-width: 767px)';
  const mediaQueryList = window.matchMedia(mediaQuery);
  let carrousel;
  let sliderActive = false;

  const settings = {
    focusAt: 'center',
    perView: 1,
    rewind: false,
    startAt: 1,
    type: 'slider',
    peek: 75,
    gap: 25
  };

  function createBullet() {
    let button;
    const nbSlide = jQuery('.tools-module').find('.glide__slide').length;
    const bulletWrapper = jQuery('.tools-module').find('.glide__bullets');
    if (nbSlide > 0) {
      for (let i = 0; i < nbSlide; i++) {
        button = `<button class="slider__bullet glide__bullet" data-glide-dir="=${i}"></button>`;
        bulletWrapper.append(button);
      }
    }
  }

  function deleteBullet() {
    const bullets = jQuery('.tools-module').find('.glide__bullet');
    bullets.remove();
  }


  if (mediaQueryList.matches) {
    carrousel = new Glide('.tools-slider', settings);
    carrousel.mount();
    sliderActive = true;
    createBullet();
  }


  mediaQueryList.addEventListener('change', event => {
    if (event.matches) {
      if (!sliderActive) {
        carrousel = new Glide('.tools-slider', settings);
        carrousel.mount();
        createBullet();
        sliderActive = true;
      }
    } else {
      carrousel.destroy();
      deleteBullet();
      sliderActive = false;
    }
  });
}

import React from 'react';
import { connect } from 'react-redux';

import MainComponent from '../common/components/MainComponent';

import Form from './components/Form';
import Results from './components/Results';

import { search } from '../common/redux/actions/SearchActions';

class Search extends MainComponent {

  constructor(props) {
    super(props);

    this._bind('onChange', 'onFocus', 'onMouseEnter', 'onMouseLeave');

    this.state = {
      currentValue: '',
      focus: false
    };
  }

  // On componentDidMount, refresh addFormLink button with Drupal behviors.
  // IE Add drupal ajax on button
  componentDidMount() {
    const { addFormLink } = this.refs;
    Drupal.attachBehaviors(addFormLink);
  }

  render() {
    const { dispatch, results, questions } = this.props;
    const { currentValue, focus } = this.state;

    const completeResults = [];
    results.forEach((item) => {
      // Get complete question object from data
      const completeQuestion = questions.find(
        question => parseInt(question.id, 10) === parseInt(item, 10)
      );

      if (completeQuestion) {
        // Remove id and title doulbon
        const isPresent = completeResults.find(
          result => {
            return result.id === completeQuestion.id || result.t === completeQuestion.t;
          }
        );

        if (!isPresent) {
          completeResults.push(completeQuestion);
        }
      }
    });

    const hasResults = currentValue.length !== 0 && completeResults.length && focus;

    const resultsComponent = hasResults ? <Results list={completeResults} /> : null;

    const props = {
      id: 'form-content',
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
      className: hasResults ? 'open' : null
    };

    return (
      <div {...props}>
        <Form dispatch={dispatch} onChange={this.onChange} onFocus={this.onFocus} />
        {resultsComponent}
      </div>
    );
  }

  onChange(value) {
    this.setState({currentValue: value});
    if (value.length < 3) { return; }
    const { dispatch } = this.props;
    clearTimeout(this.searchTimeout);
    Drupal.currentSearch = value;
    this.searchTimeout = setTimeout(() => dispatch(search(value)), 100);
  }

  onMouseEnter() {
    this.setState({focus: true});
  }

  onFocus() {
    this.setState({focus: true});
  }

  onMouseLeave() {
    this.setState({focus: false});
  }
}

export default connect((state) => {
  return {
    hasSearched: state.search.hasSearched,
    questions: state.questions.questions,
    results: state.search.results
  };
})(Search);

import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';
import ButtonPopin from '../../button-popin/ButtonPopin';

const propTypes = {
  resized: React.PropTypes.func.isRequired
};

class Media extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('onImageLoaded');
  }

  render() {
    const { data } = this.props;

    return (
      <div className="data-holder">
        <div className="img-holder">
          <picture onLoad={ this.onImageLoaded }>
            <source
              srcSet={ `${data.thumb.p} 1x, ${data.thumb.p2x} 2x` }
              media="(max-width: 767px)"
            />
            <img
              src={ data.thumb.d }
              alt={ data.title }
              ref="image"
            />
          </picture>
        </div>
        <ul className="tool-list">
          <li className="zoom-button">
            <ButtonPopin type="image" content={data.thumb.p2x} animateFrom={this.refs.image}/>
          </li>
          <li>
            <a href={data.file} target="_blank" className="download">Télécharger</a>
          </li>
          <li>
            <ButtonPopin type="transcript" content={data.transcription} />
          </li>
        </ul>
      </div>
    );
  }

  onImageLoaded() {
    const { resized } = this.props;
    resized();
  }

}

Media.propTypes = propTypes;

export default Media;

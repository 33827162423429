import scriptjs from 'scriptjs';
import Video from './Video';


export default function initTestimonies() {
  scriptjs('https://www.youtube.com/iframe_api');
  const $ITEMS = document.querySelectorAll('.testimonies__player');

  const VIDEOS = [];

  window.onYouTubeIframeAPIReady = () => {
    $ITEMS.forEach((item) => {
      const video = new Video(item);
      VIDEOS.push(video);
    });
  };
}

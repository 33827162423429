import React from 'react';
import Paginator from 'paginator';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  current: React.PropTypes.number.isRequired,
  onPageChange: React.PropTypes.func.isRequired,
  step: React.PropTypes.number.isRequired,
  total: React.PropTypes.number.isRequired
};

class Pagination extends BaseComponent {
  constructor(props) {
    super(props);

    this._bind('goNext', 'goPrevious', 'handleClick');
  }

  goNext(event) {
    event.preventDefault();
    const {current} = this.props;
    this.props.onPageChange(current + 1);
  }

  goPrevious(event) {
    event.preventDefault();
    const {current} = this.props;
    this.props.onPageChange(current - 1);
  }

  handleClick(event) {
    event.preventDefault();
    const page = parseInt(event.target.getAttribute('data-id'), 10);
    if (page !== this.props.current) {
      this.props.onPageChange(page);
    }
  }

  render() {
    const {current, step, total} = this.props;
    const paginator = new Paginator(step, step);
    const paginationInfo = paginator.build(total, current + 1);

    if (paginationInfo.total_pages === 1) {
      return null;
    }

    const pagination = [...new Array(paginationInfo.total_pages)];

    return (
      <ul className="mosaic-pagination">
        { current > 0 && <li><a href="#" onClick={this.goPrevious}><i className="previous-page" /></a></li>}
        {
          pagination.map((page, index) =>
            <li className={index === current ? 'selected' : ''} key={index}>
              <a href="#" onClick={this.handleClick} data-id={index} href="#">{index + 1}</a>
            </li>
          )
        }
        { current < (paginationInfo.total_pages - 1) && <li><a href="#" onClick={this.goNext}><i className="next-page" /></a></li>}
      </ul>
    );
  }
}

export default Pagination;

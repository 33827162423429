import {
  CITIES_LOAD,
  CITIES_LOAD_SUCCESS,
  CITIES_LOAD_ERROR,
  CITIES_FIND_NEAREST,
  CITIES_NEAREST_CENTERED
} from '../constants/CitiesTypes';

const initState = {
  cities: [],
  positions: [],
  findNearest: false,
  isFetching: false,
  list: null
};

export function cities(state = initState, action) {
  switch (action.type) {
    case CITIES_LOAD: {
      return {
        ...state,
        isFetching: true
      };
    }
    case CITIES_LOAD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        cities: action.cities,
        positions: action.positions
      };
    }
    case CITIES_LOAD_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    case CITIES_FIND_NEAREST: {
      return {
        ...state,
        findNearest: true
      };
    }
    case CITIES_NEAREST_CENTERED: {
      return {
        ...state,
        findNearest: false
      };
    }
    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';

import BaseComponent from '../../common/components/BaseComponent';

import Card from './Card';

class CardList extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      isEmpty: false,
      shouldUpdate: true
    };
  }

  render() {
    const { blocks } = this.props;
    const { isEmpty } = this.state;

    return (
      <div className="card-list">
        <h1 className="card-list__title">Villes ambassadrices du don d’organes</h1>
        <div className="card-list__list">
          { blocks.map((block, ukey) => {
            return (
              <Card data={ block } key={ukey}/>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    list: state.events.list,
  };
})(CardList);

import BaseComponent from './BaseComponent';

import { requestDataIfNeeded } from '../redux/actions/DataActions';

class MainComponent extends BaseComponent {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestDataIfNeeded());
  }

}

export default MainComponent;

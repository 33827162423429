import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { showFilter, hideFilter } from '../../common/redux/actions/FilterAction';

import BaseComponent from '../../common/components/BaseComponent';

import { sendTag } from '../../non-react/at-internet';

const propTypes = {
  filters: React.PropTypes.array.isRequired,
  updateFilter: React.PropTypes.func.isRequired
};

class Filter extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('onClickHandler', 'onOpenHandler', 'setFilter');

    this.state = {
      staticOpen: false
    };
  }

  render() {
    const { staticOpen } = this.state;
    const { categories, filters, isFilterOpen } = this.props;
    const filterBlockClass = ['filter-block'];
    const filterListWrapperClass = ['filter-drop'];

    if (isFilterOpen) {
      filterBlockClass.push('active');
      filterListWrapperClass.push('filter-drop--open');
    } else {
      filterListWrapperClass.push('filter-drop--closed');
    }

    if (staticOpen && isFilterOpen) {
      filterListWrapperClass.push('js-desktop-open');
    }

    return (
      <div className={filterBlockClass.join(' ')}>
        <a href="#" className="filter-opener" onClick={this.onClickHandler}>
          <span className="closed">Filtrer</span>
        </a>
        <div className={ filterListWrapperClass.join(' ')}>
          <a href="#" className="filter-opener" onClick={this.onClickHandler}>
            <span className="opened">retour</span>
          </a>
          <p className="title">Filtrer</p>
          <ul className="filter-list">
            <li className={classNames({active: filters.length === 0}) }>
              <a href="#" className="gray" onClick={this.setFilter}>Tous les thèmes</a>
            </li>
            { categories.map((category, ukey) => {
              const key = `filter-${ukey}`;
              return (
                <li
                  key={key}
                  className={classNames({
                    active: filters.indexOf(parseInt(category.id, 10)) !== -1
                  })}
                >
                  <a
                    href="#"
                    className={category.c}
                    data-id={category.id}
                    onClick={this.setFilter}
                  >
                    { category.t }
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  setFilter(event) {
    event.preventDefault();

    const { updateFilter } = this.props;
    const cid = parseInt(event.currentTarget.getAttribute('data-id'), 10);
    if (!isNaN(cid)) {
      let text = event.currentTarget.textContent;
      if (text.indexOf('?') !== -1) {
        text = text.substring(0, text.length - 2);
      }
      text = text.toLowerCase();
      sendTag(`Filtre_${text}`);
    }
    updateFilter(cid);
  }

  onClickHandler(event) {
    event.preventDefault();

    if (this.props.isFilterOpen) {
      this.props.dispatch(hideFilter());
    } else {
      this.props.dispatch(showFilter());
    }
  }

  onOpenHandler(event) {
    event.preventDefault();

    const { staticOpen } = this.state;

    this.setState({
      staticOpen: !staticOpen
    });
  }
}

Filter.propTypes = propTypes;

export default connect((state) => {
  return {
    categories: state.questions.categories,
    isFilterOpen: state.filter.isFilterOpen
  };
})(Filter);

export default {
  URL: {
    DATA: '/sites/default/files/data.json',
    SEARCH: '/search_api_live_results/search_api_page_4/',
    EVENTS: '/sites/default/files/event.json',
    TOOLS: '/sites/default/files/outils.json',
    CITIES: '/sites/default/files/ville.json',
    EDITORIAL: '/sites/default/files/ville-editorial.json'
  },
  SEARCH: {
    PAGINATION: 10
  },
  AMBASSADORS: {
    GOOGLE_API: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAp-5KIKd0bvgGicbSqT2HADxbBxbEbpkU&libraries=geometry',
    MARKER: `${Drupal.settings.pathToTheme}/assets/images/marker-ruban.png`,
    BOUNDS: {
      SW: {
        lat: 42.333014,
        lng: -4.795341899999999
      },
      NE: {
        lat: 51.089166,
        lng: 8.2335491
      }
    },
    CLUSTERER: {
      minimumClusterSize: 2,
      maxZoom: 12,
      // maxZoom: 8,
      ignoreHidden: true,
      enableRetinaIcons: true,
      averageCenter: true,
      styles: [{
        url: `${Drupal.settings.pathToTheme}/assets/images/marker-ruban.png`,
        width: 100,
        height: 81,
        // strange values...
        anchorIcon: [61, 26],
        anchorText: [-31, 18],
        textSize: 23,
        textColor: '#0000000',
        fontFamily: 'Arial',
        fontWeight: 'normal'
      }]
    }
  },
  EVENTS: {
    GOOGLE_API: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAp-5KIKd0bvgGicbSqT2HADxbBxbEbpkU&libraries=geometry',
    MARKER: `${Drupal.settings.pathToTheme}/assets/images/marker.png`,
    BW_MARKER: `${Drupal.settings.pathToTheme}/assets/images/bw-marker.png`,
    MONTH: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre'
    ],
    BOUNDS: {
      SW: {
        lat: 42.333014,
        lng: -4.795341899999999
      },
      NE: {
        lat: 51.089166,
        lng: 8.2335491
      }
    },
    CLUSTERER: {
      minimumClusterSize: 2,
      maxZoom: 12,
      // maxZoom: 8,
      ignoreHidden: true,
      enableRetinaIcons: true,
      styles: [{
        url: `${Drupal.settings.pathToTheme}/assets/images/marker.png`,
        width: 52,
        height: 65,
        // strange values...
        anchorIcon: [61, 26],
        anchorText: [-6, 0],
        textSize: 23,
        textColor: '#ffffff',
        fontFamily: 'Arial',
        fontWeight: 'normal'
      }]
    }
  }
};

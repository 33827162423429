import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Isotope from 'isotope-layout';

import MainComponent from '../common/components/MainComponent';
import { TweenMax } from '../common/lib/gsap.js';

import Filter from './components/Filter';
import Pagination from './components/Pagination';
import Question from './components/Question';
import Sorter from './components/Sorter';
import View from './components/View';

class Mosaic extends MainComponent {

  constructor(props) {
    super(props);

    this._bind('onSortChange', 'onViewChange', 'resized', 'updateFilter', 'selectPage');

    const cookieFilters = Cookies.get('filters');
    let initFilters = [];

    if (cookieFilters) {
      initFilters = cookieFilters.split(',').map((id) => parseInt(id, 10));
    }

    if (Drupal.settings.thematique) {
      initFilters.push(14);
    }

    this.state = {
      filters: initFilters,
      currentPage: parseInt(Cookies.get('page'), 10) || 0,
      open: null,
      shouldUpdate: true,
      sort: Cookies.get('sort') || 'id',
      totalResults: 0,
      transitionDuration: 'Os',
      view: Cookies.get('view') || 'box'
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.isotope && this.props.questions) {
      this.isotope = new Isotope(this.refs.mosaic, {
        getSortData: {
          id: function getId(itemElem) {
            return parseInt(jQuery(itemElem).data('id'), 10);
          },
          weight: function getWeight(itemElem) {
            return parseInt(jQuery(itemElem).data('weight'), 10);
          }
        },
        itemSelector: '.mosaic-question',
        masonry: {
          columnWidth: 0,
          gutter: 0
        },
        sortBy: 'id',
        sortAscending: false,
        transitionDuration: '0s'
      });
      this.isotope.on('arrangeComplete', (filteredItems) => {
        const { shouldUpdate } = this.state;
        if (shouldUpdate) {
          this.setState({
            shouldUpdate: false
          });
        }

        filteredItems.forEach((item) => {
          const currentItem = item;
          if (currentItem.element.style.visibility !== 'hidden') {
            return;
          }

          currentItem.element.style.visibility = 'visible';
          currentItem.element.style.opacity = 0;

          jQuery(currentItem.element).animate({opacity: 1});
        });
      });
    }

    if (this.props.isFilterOpen !== prevProps.isFilterOpen) {
      if (this.props.isFilterOpen) {
        jQuery('#mosaic-questions').css('margin-top', jQuery('.filter-drop').innerHeight() + 15);
      } else {
        jQuery('#mosaic-questions').css('margin-top', 0);
      }
    }

    if (this.state.shouldUpdate) {
      setTimeout(this.resized, 200);
    }

    if (this.state.view !== prevState.view) {
      jQuery('#mosaic-questions').css('opacity', 0);
      setTimeout(() => {
        jQuery('#mosaic-questions').css('opacity', 1);
      }, 200);
    }
  }

  onViewChange(view) {
    Cookies.set('view', view);
    Cookies.set('page', 0);
    this.setState({
      shouldUpdate: true,
      currentPage: 0,
      transitionDuration: '0s',
      view
    });
  }

  onSortChange(sort) {
    Cookies.set('sort', sort);
    Cookies.set('page', 0);
    this.setState({
      currentPage: 0,
      sort,
      shouldUpdate: true,
      transitionDuration: '0.4s',
    });
    this.isotope.arrange({
      sortBy: sort
    });
  }

  render() {
    const { questions, step } = this.props;
    const { currentPage, filters, sort, totalResults, view } = this.state;

    const className = `${view}-holder`;

    const numResultsLabel = totalResults > 0 ? `${totalResults} Résultats` : '';
    return (
      <div className="holder mosaic-list">
        <div className="mosaic-header">
          <p className="results-label">{numResultsLabel}</p>
          <Filter filters={filters} updateFilter={this.updateFilter}/>
          <Sorter current={sort} onSortChange={this.onSortChange} />
          <View current={view} onViewChange={this.onViewChange} />
        </div>
        <div id="mosaic-questions" className={className} ref="mosaic">
          { questions.map((question, ukey) =>
            (<Question
              data={question}
              key={ukey}
              resized={this.resized}
              view={view}
            />)
          )}
        </div>
        <Pagination current={currentPage} onPageChange={this.selectPage} step={step} total={totalResults} />
      </div>
    );
  }

  selectPage(page) {
    TweenMax.to('body, html', 0.5, { scrollTop: 0 });
    Cookies.set('page', page);
    this.setState({
      currentPage: page,
      shouldUpdate: true,
      transitionDuration: '0.4s'
    });
  }

  resized() {
    const { questions, step } = this.props;
    const { filters, currentPage, sort, transitionDuration } = this.state;
    const minElements = currentPage * step;
    const maxElements = (currentPage + 1) * step;

    const itemsFiltered = questions
      .filter(question => filters.length === 0 || filters.indexOf(parseInt(question.c, 10)) !== -1);

    const itemsToDisplay = itemsFiltered
      .sort((a, b) => {
        const valueA = parseInt(sort === 'id' ? a.p : a.id, 10);
        const valueB = parseInt(sort === 'id' ? b.p : b.id, 10);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = -1;
        } else if (valueA < valueB) {
          comparison = 1;
        }
        return comparison;
      })
      .slice(minElements, maxElements)
      .map(question => parseInt(question.id, 10));


    this.isotope.options.transitionDuration = transitionDuration;
    this.isotope.arrange({
      isJQueryFiltering: false,
      filter: (item) => itemsToDisplay.indexOf(parseInt(item.getAttribute('data-id'), 10)) !== -1
    });

    this.setState({
      totalResults: itemsFiltered.length
    });
  }

  updateFilter(cid) {
    const { filters } = this.state;
    const newFilters = [];
    if (cid) {
      const index = filters.indexOf(cid);
      if (index === -1) {
        newFilters.push(cid);
      }
      filters.forEach(id => {
        if (id !== cid) {
          newFilters.push(id);
        }
      });
    }

    Cookies.set('filters', newFilters);
    Cookies.set('page', 0);

    this.setState({
      filters: newFilters,
      currentPage: 0,
      shouldUpdate: true,
      transitionDuration: '0.4s'
    });
  }
}

export default connect((state) => {
  return {
    isFilterOpen: state.filter.isFilterOpen,
    questions: state.questions.questions,
    step: state.questions.step
  };
})(Mosaic);

import { Elastic } from '../../common/lib/gsap.js';

export const WIDTH = 120;
export const HEIGHT = 577;

export const CUBIC = { p1: 10, p2: 90, p1b: 160, p2b: 65 };
export const CUBIC_OVER = { p1: 50, p2: 120, p1b: 0, p2b: 150 };
export const CUBIC_OUT = Object.assign(CUBIC, { ease: Elastic.easeOut.config(1, 0.3) });
export const CUBIC_CLICK = { p1: 0, p2: 0, p1b: 100, p2b: 55 };
export const CUBIC_PAN = {p1: 0, p2: 40, p1b: 100, p2b: 50};

export const SIDE_LEFT = 'left';
export const SIDE_RIGHT = 'right';

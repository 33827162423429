import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  data: React.PropTypes.object.isRequired,
  resized: React.PropTypes.func.isRequired,
  view: React.PropTypes.string.isRequired
};

class Question extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('onClickHandler', 'onImageLoaded');
  }

  render() {
    const { data, view } = this.props;
    const wrapClasses = ['box-wrap', 'mosaic-question', `category-${data.c}`];
    const boxClasses = ['box', data.category.c];
    const aClasses = ['btn'];
    const mediaClasses = [];
    let image;
    let videoIcon;

    if (data.media) {
      aClasses.push('bottom', data.category.c);
      switch (data.media.type) {
        case 'image':
          mediaClasses.push('img-holder');
          break;
        case 'video':
          mediaClasses.push('video');
          videoIcon = (<a href={ data.l } className="icon-player">
                          <span className="hidden">icon-player</span>
                      </a>);
          break;
      }

      image = (<div className={ mediaClasses.join(' ') }>
        <picture onLoad={this.onImageLoaded}>
          <source
            srcSet={ `${data.media.thumb.d} 1x, ${data.media.thumb.r} 2x` }
            media="(min-width: 320px)"
          />
          <img
            src={ data.media.thumb.r }
            alt={ data.media.title }
          />
        </picture>

        { videoIcon }
        </div>);
    }

    const isBox = view === 'box';

    return (
      <div className={ wrapClasses.join(' ')} data-category={data.c} data-id={data.id} data-weight={data.p}>
        <div className={ boxClasses.join(' ')} onClick={this.onClickHandler}>
          <div className="text">
            <span className="info">{ data.category.t }</span>{!isBox && <span className="text-info">{ data.a }</span>}
            <h2 dangerouslySetInnerHTML={{ __html: data.t }}></h2>
            {isBox && <span className="text-info">{ data.a }</span>}
            <a href={ data.l } className={ aClasses.join(' ')}>VOIR LA RÉPONSE</a>
          </div>
          { isBox && image }
        </div>
      </div>
    );
  }

  onClickHandler(event) {
    const { data } = this.props;

    event.preventDefault();
    window.location = data.l;
  }

  onImageLoaded() {
    const { resized } = this.props;
    resized();
  }
}

Question.propTypes = propTypes;

export default Question;

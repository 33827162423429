import Cookies from 'cookies-js';

export default function overrideHomeThematicClick() {
  if (!Drupal.settings.isFront) {
    return;
  }

  jQuery('.vocabulary-thematique a').click((event) => {
    const thematicId = jQuery(event.target).data('id');
    Cookies.set('page', 0);
    Cookies.set('filters', [thematicId]);
  });
}

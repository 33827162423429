import React from 'react';
import { connect } from 'react-redux';
import Hammer from 'react-hammerjs';

import MainComponent from '../common/components/MainComponent';
import Arrow from './components/Arrow';
import Slide from './components/Slide';

import {
    SIDE_LEFT, SIDE_RIGHT,
    CUBIC_OVER, CUBIC_OUT
} from './constants/Animation';

class Caroussel extends MainComponent {
  constructor(props) {
    super(props);
    this._bind('onAnimationStarted',
        'onAnimationComplete',
        'handlePan',
        'handlePanEnd'
    );
    this.state = {
      available: true,
      slides: [],
      distance: null,
      panLeft: null,
      panRight: null,
      slideLeft: false,
      slideRight: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { autostart, carousel, categories, questions } = nextProps.questions;
    const slides = [];
    const { leftArrow, rightArrow } = this.refs;
    carousel.forEach((item) => {
      let questionID;
      if (item.type === 'question_reponse') {
        questionID = item.question;
      } else if (item.type === 'random') {
        const currentCategory = categories.find(
          category => parseInt(category.id, 10) === parseInt(item.category, 10)
        );
        if (currentCategory) {
          const ids = currentCategory.questions;
          const sliceQuestions = ids.slice(0, parseInt(item.random, 10));
          questionID = sliceQuestions[Math.floor(Math.random() * sliceQuestions.length)];
        }
      } else if (item.type === 'carousel_media') {
        slides.push(item);
      }

      if (questionID) {
        const currentQuestion = questions.find(
          question => parseInt(question.id, 10) === parseInt(questionID, 10)
        );
        if (currentQuestion) {
          slides.push(currentQuestion);
        }
      }
    });
    const newState = this.state;
    newState.leftArrow = leftArrow;
    newState.rightArrow = rightArrow;
    this.setState(newState);
    this.setState({slides});

    if (autostart) {
      this.startAutomatic();
    }
  }

  render() {
    const { slides, available, panLeft, panRight, slideLeft, slideRight } = this.state;
    if (slides.length === 0) {
      return null;
    }
    const currentSlide = slides[0];
    const previousSlide = slides[slides.length - 1];
    const nextSlide = slides[1];

    return (
      <Hammer onPanStart={this.handlePanStart} onPan={this.handlePan} onPanEnd={this.handlePanEnd}>
        <div className="carousel">
          <div className="mask">
            <Slide question={currentSlide}/>
          </div>
          <Arrow
            ref="leftArrow"
            panO={panLeft}
            isMainCarousel
            launchSlide={slideLeft}
            side={ SIDE_LEFT }
            color={previousSlide.category ? previousSlide.category.c : 'black'}
            available={available}
            onAnimationStarted={this.onAnimationStarted}
            onAnimationComplete={this.onAnimationComplete}
          />
          <Arrow
            ref="rightArrow"
            panO={panRight}
            isMainCarousel
            launchSlide={slideRight}
            side={ SIDE_RIGHT }
            color={nextSlide.category ? nextSlide.category.c : 'black'}
            available={available}
            onAnimationStarted={this.onAnimationStarted}
            onAnimationComplete={this.onAnimationComplete}
          />
        </div>
      </Hammer>
    );
  }

  startAutomatic() {
    this.automaticInterval = setTimeout(() => this.refs.rightArrow.onClickHandler(), 5000);
  }

  stopAutomatic() {
    clearTimeout(this.automaticInterval);
  }

  handlePan(e) {
    // const { leftArrow, rightArrow } = this.refs;
    const { available } = this.state;
    if (!available) {
      return;
    }

    let d = e.deltaX;

    if (d > 50) {
      d = 50;
    }
    if (d < -50) {
      d = -50;
    }

    this.setState({distance: d});

    const ratio = this.remap(Math.abs(d), 0, 50, 0, 1);
    const o = {
      p1: this.lerp(CUBIC_OUT.p1, CUBIC_OVER.p1, ratio),
      p1b: this.lerp(CUBIC_OUT.p1b, CUBIC_OVER.p1b, ratio),
      p2: this.lerp(CUBIC_OUT.p2, CUBIC_OVER.p2, ratio),
      p2b: this.lerp(CUBIC_OUT.p2b, CUBIC_OVER.p2b, ratio)
    };

    if (d < 0) {
      this.setState({panLeft: null, panRight: o});
    } else if (d > 0) {
      this.setState({panLeft: o, panRight: null});
    }
  }

  handlePanEnd(e) {
    this.setState({panCenter: null, panLeft: null, panRight: null});

    if (e.deltaX <= -50) {
      this.setState({slideRight: true});
    } else if (e.deltaX >= 50) {
      this.setState({slideLeft: true});
    }
  }

  // On arrow animation, desactivate user interaction
  onAnimationStarted() {
    this.stopAutomatic();
    this.setState({available: false, slideLeft: false, slideRight: false});
  }

  // On animation complete: reset arrows state, reorder slides, activate user interaction
  onAnimationComplete(direction) {
    const { autostart } = this.props.questions;
    const { slides } = this.state;
    const { leftArrow, rightArrow } = this.refs;
    leftArrow.reset();
    rightArrow.reset();
    switch (direction) {
      case -1:
        slides.unshift(slides.pop());
        break;
      case 1:
        slides.push(slides.shift());
        break;
      default:
    }

    this.setState({
      available: true,
      slides,
      slideLeft: false,
      slideRight: false
    });

    if (autostart) {
      this.startAutomatic();
    }
  }

  lerp(v0, v1, t) {
    return v0 * (1 - t) + v1 * t;
  }

  remap(val, min1, max1, min2, max2) {
    return Math.round((min2 + (max2 - min2) * ((val - min1) / (max1 - min1))) * 10000000000)
        / 10000000000;
  }
}
export default connect((state) => {
  return {
    questions: state.questions
  };
})(Caroussel);

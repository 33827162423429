import React from 'react';
import { connect } from 'react-redux';

import BaseComponent from '../common/components/BaseComponent';

import { fetchCities } from '../common/redux/actions/CitiesActions';
import { fetchEditorial } from '../common/redux/actions/EditorialActions';

import CardList from './components/CardList';
import Map from './components/Map';

class Cities extends BaseComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCities());
    dispatch(fetchEditorial());
  }

  render() {
    const { blocks, positions, rgaa } = this.props;

    return (
      <div className="ambassador__grid">
        <Map markers={positions} rgaa={ rgaa }/>
        <CardList blocks={blocks} />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    blocks: state.editorial.blocks,
    positions: state.cities.cities
  };
})(Cities);

import { DATA_LOAD, DATA_LOAD_SUCCESS, DATA_LOAD_ERROR } from '../constants/DataTypes';

const initState = {
  isFetching: false,

  carousel: [],
  categories: [],
  questions: [],
  step: null
};

export function questions(state = initState, action) {
  switch (action.type) {
    case DATA_LOAD: {
      return {
        ...state,
        isFetching: true
      };
    }
    case DATA_LOAD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        autostart: action.autostart,
        carousel: action.carousel,
        categories: action.categories,
        questions: action.questions,
        step: action.pagination
      };
    }
    case DATA_LOAD_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    default:
      return state;
  }
}

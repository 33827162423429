import React from 'react';
import { connect } from 'react-redux';
import { hideSorter, showSorter } from '../../common/redux/actions/FilterAction';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  current: React.PropTypes.string.isRequired,
  onSortChange: React.PropTypes.func.isRequired,
};

class Sorter extends BaseComponent {
  constructor(props) {
    super(props);

    this._bind('handleClick', 'onClickHandler');
  }

  handleClick(event) {
    const sort = event.target.getAttribute('value');
    if (sort !== this.props.current) {
      this.props.onSortChange(sort);
      this.props.dispatch(hideSorter());
    }
  }

  onClickHandler(event) {
    event.preventDefault();
    if (this.props.isSorterOpen) {
      this.props.dispatch(hideSorter());
    } else {
      this.props.dispatch(showSorter());
    }
  }

  render() {
    const options = [{
      id: 'id',
      label: 'Les plus consultés'
    }, {
      id: 'weight',
      label: 'Les plus récentes'
    }];

    const wrapperClassName = ['sorter-block', this.props.isSorterOpen ? 'open' : ''];

    return (
      <div className={wrapperClassName.join(' ')}>
        <a href="#" className="sorter-opener" onClick={this.onClickHandler}>
          <span className="closed">Trier</span>
        </a>
        <ul className="sorter-drop">
          {
            options.map(({id, label}, index) => {
              const checked = this.props.current === id;
              const className = checked ? 'selected' : '';
              return (
                <li key={index} className={className}>
                  <input onClick={this.handleClick} type="radio" id={id} name="sort" value={id} checked={checked} />
                  <label htmlFor={id}>{label}</label>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}

Sorter.propTypes = propTypes;

export default connect(state => {
  return {
    isSorterOpen: state.filter.isSorterOpen
  };
})(Sorter);

export default [{
  featureType: 'all',
  elementType: 'labels.text.fill',
  stylers: [{
    saturation: 36
  }, {
    color: '#333333'
  }, {
    lightness: 40
  }]
}, {
  featureType: 'all',
  elementType: 'labels.text.stroke',
  stylers: [{
    visibility: 'on'
  }, {
    color: '#ffffff'
  }, {
    lightness: 16
  }]
}, {
  featureType: 'all',
  elementType: 'labels.icon',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'administrative',
  elementType: 'all',
  stylers: [{
    visibility: 'on'
  }]
}, {
  featureType: 'administrative',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#fefefe'
  }, {
    lightness: 20
  }]
}, {
  featureType: 'administrative',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#fefefe'
  }, {
    lightness: 17
  }, {
    weight: 1.2
  }]
}, {
  featureType: 'landscape',
  elementType: 'all',
  stylers: [{
    lightness: '100'
  }, {
    gamma: '8.71'
  }]
}, {
  featureType: 'landscape',
  elementType: 'geometry',
  stylers: [{
    color: '#f5f5f5'
  }, {
    lightness: 20
  }]
}, {
  featureType: 'landscape.natural',
  elementType: 'all',
  stylers: [{
    visibility: 'on'
  }, {
    saturation: '-27'
  }, {
    lightness: '100'
  }, {
    color: '#f1f1f1'
  }]
}, {
  featureType: 'landscape.natural.terrain',
  elementType: 'all',
  stylers: [{
    visibility: 'on'
  }]
}, {
  featureType: 'poi',
  elementType: 'all',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi',
  elementType: 'geometry',
  stylers: [{
    color: '#f5f5f5'
  }, {
    lightness: 21
  }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry',
  stylers: [{
    color: '#dedede'
  }, {
    lightness: 21
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 17
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 29
  }, {
    weight: 0.2
  }]
}, {
  featureType: 'road.arterial',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 18
  }]
}, {
  featureType: 'road.local',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff'
  }, {
    lightness: 16
  }]
}, {
  featureType: 'transit',
  elementType: 'geometry',
  stylers: [{
    color: '#f2f2f2'
  }, {
    lightness: 19
  }]
}, {
  featureType: 'transit.station',
  elementType: 'all',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'water',
  elementType: 'all',
  stylers: [{
    visibility: 'on'
  }, {
    hue: '#ff0000'
  }]
}, {
  featureType: 'water',
  elementType: 'geometry',
  stylers: [{
    color: '#c9cece'
  }, {
    lightness: 17
  }]
}];

import Glide from '@glidejs/glide';

let currentIndex;
const knowledgeCarousels = [];

function DisableControls(glide, Components, Events) {
  const component = {
    handleDisable() {
      const slidesCount = Components.Html.slides.length;

      if (glide.index <= (window.matchMedia('(min-width: 1024px)').matches ? 1 : 0)) {
        jQuery('[data-glide-dir="<"]').addClass('disabled');
      } else {
        jQuery('[data-glide-dir="<"]').removeClass('disabled');
      }

      if (glide.index >= slidesCount - 1) {
        jQuery('[data-glide-dir=">"]').addClass('disabled');
      } else {
        jQuery('[data-glide-dir=">"]').removeClass('disabled');
      }
    },
  };

  Events.on('build.after', () => {
    component.handleDisable();
  });
  Events.on('run.after', () => {
    component.handleDisable();
  });
  return component;
}

function selectKnowledgeCarousel(index) {
  jQuery('.knowledge-list a').removeClass('active');
  jQuery('.view-display-id-knowledge section').hide();
  jQuery(`.view-display-id-knowledge section[data-id=${index}]`).show();
  jQuery(`.knowledge-list a[data-id=${index}]`).addClass('active');

  if (!!currentIndex) {
    knowledgeCarousels[currentIndex].disable();
  }

  // setTimeout(() => {
  knowledgeCarousels[index].mount({ DisableControls }).enable();
  // }, 1000);

  // jQuery('.knowledge-description').text(
  //   jQuery(`.view-display-id-knowledge section[data-id=${index}] .knowledge-description`).first().data('value')
  // );

  currentIndex = index;
}

export default function initCarousel() {
  if (Drupal.settings.isRGAA || jQuery('.view-display-id-knowledge').length === 0) {
    return;
  }

  jQuery('.view-display-id-knowledge .view-content')
    .prepend('<ul class="knowledge-list"></ul><p class="knowledge-description"></p>');

  jQuery('.view-display-id-knowledge section').each(function createKnowledgeCarousel(index) {
    jQuery(this).attr('data-id', index);
    const title = jQuery(this).find('h2').text();
    jQuery(this).find('h2').remove();
    jQuery('.knowledge-list').append(`<li><a href="#" data-id="${index}">${title}</a></li>`);

    const carousel = jQuery(this).find('.knowledge-holder').get(0);
    knowledgeCarousels[index] = new Glide(carousel, {
      breakpoints: {
        680: {
          perView: 1
        },
        900: {
          perView: 2
        },
        1280: {
          perView: 3,
        },
        1480: {
          perView: 4,
        }
      },
      focusAt: 'center',
      perView: 5,
      rewind: false,
      startAt: window.matchMedia('(min-width: 1024px)').matches ? 1 : 0,
      type: 'slider'
    }).disable();

    knowledgeCarousels[index].on('build.after', () => {
      const slideHeight = jQuery('.glide__slide--active').outerHeight();
      const glideTrack = jQuery('.glide__track').outerHeight();
      if (slideHeight !== glideTrack) {
        const newHeight = slideHeight;
        jQuery('.glide__track').css('height', newHeight);
      }
    });

    knowledgeCarousels[index].on('run.after', () => {
      const slideHeight = jQuery('.glide__slide--active').outerHeight();
      const glideTrack = jQuery('.glide__track').outerHeight();
      if (slideHeight !== glideTrack) {
        const newHeight = slideHeight;
        jQuery('.glide__track').css('height', newHeight);
      }
    });
  });

  jQuery('.knowledge-list a').click(function onKnowledgeClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (window.matchMedia('(max-width: 768px)').matches) {
      if (jQuery(this).hasClass('active')) {
        jQuery('.knowledge-list').toggleClass('open');
        return;
      } else { // eslint-disable-line no-else-return
        jQuery('.knowledge-list').prepend((jQuery(this).parent()));
        jQuery('.knowledge-list').removeClass('open');
      }
    }

    selectKnowledgeCarousel(jQuery(this).data('id'));
  });

  selectKnowledgeCarousel(0);
}

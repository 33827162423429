import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Filter from './Filter';

import BaseComponent from '../../common/components/BaseComponent';

import { sendTag } from '../../non-react/at-internet';

const propTypes = {
  filters: React.PropTypes.array.isRequired,
  updateFilter: React.PropTypes.func.isRequired
};

class Filters extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('onClickHandler', 'onOpenHandler', 'setFilter', 'handleClickOutside');
    this.state = {
      staticOpen: false,
      isOpen: false,
    };
  }

  handleClickOutside(event) {
    const { filtersref } = this.refs;
    if (filtersref && !filtersref.contains(event.target)) {
      filtersref.classList.remove('is-open');
      this.setState({
        isOpen: false
      });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { staticOpen, isOpen } = this.state;
    const { thematiques, types, filters } = this.props;
    const filterBlockClass = ['tools-filters'];
    const filterListWrapperClass = ['tools-filters__drop'];

    if (isOpen) {
      filterBlockClass.push('is-open');
      filterListWrapperClass.push('filter-drop--open');
    } else {
      filterListWrapperClass.push('filter-drop--closed');
    }

    return (
      <div className={filterBlockClass.join(' ')} ref="filtersref">
        <a href="#" className="sorter-opener" onClick={this.onClickHandler}>
          <span className="closed">Filtrer</span>
        </a>
        <div className="tools-filters__drop">
          <Filter
            title="Les Thématiques"
            items={thematiques}
            getValues={this.setFilter}
            name="themes"
            filters={filters.themes}
          />
          <Filter
            title="Type de contenu"
            items={types}
            getValues={this.setFilter}
            name="types"
            filters={filters.types}
          />
        </div>
      </div>
    );
  }

  setFilter(values, name) {
    // event.preventDefault();
    const { updateFilter } = this.props;
    updateFilter(Array.from(values), name);
  }


  onClickHandler(event) {
    event.preventDefault();

    const { filtersref } = this.refs;
    const { isOpen } = this.state;

    if (isOpen) {
      filtersref.classList.remove('is-open');
    } else {
      filtersref.classList.add('is-open');
    }

    this.setState({
      isOpen: !isOpen
    });
  }

  onOpenHandler(event) {
    event.preventDefault();

    const { staticOpen } = this.state;

    this.setState({
      staticOpen: !staticOpen
    });
  }
}

Filters.propTypes = propTypes;

export default connect((state) => {
  return {
    thematiques: state.tools.thematiques,
    types: state.tools.types,
  };
})(Filters);

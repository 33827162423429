import { POPIN_ANIMATION, POPIN_CLOSE, POPIN_OPEN }
  from '../constants/PopinTypes';

const initState = {
  animType: 'zoom',
  isOpen: false,
  type: '',
  content: '',
  animateFrom: undefined
};

export function popin(state = initState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case POPIN_OPEN:
      return {
        ...state,
        isOpen: true,
        type: payload.type,
        content: payload.content,
        animateFrom: payload.animateFrom
      };
    case POPIN_CLOSE:
      return {
        ...state,
        isOpen: false,
        type: '',
        content: '',
        animateFrom: undefined
      };
    case POPIN_ANIMATION:
      return {
        ...state,
        animType: payload.animationType
      };
    default:
      return state;
  }
}

export default function Video($item) {
  const options = {
    root: null,
    rootMargin: '75px',
    threshold: 1.0
  };

  let player;
  const id = $item.getAttribute('id');
  const vidId = $item.dataset.id;
  const videoHeight = $item.dataset.height;
  const videoWidth = $item.dataset.width;
  let isLoad = false;
  let isPaused = false;


  function handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio !== 1 && player.getPlayerState() === 1) {
        player.pauseVideo();
        isPaused = true;
      } else if (isPaused) {
        player.playVideo();
        isPaused = false;
      }
    });
  }

  const observer = new window.IntersectionObserver(handleIntersect, options);

  function onPlayerReady(event) {
    isLoad = true;

    const te = document.getElementById(id);
    observer.observe(te);
  }

  this.getPlayerState = () => {
    return player.getPlayerState();
  };

  function createPlayer() {
    player = new YT.Player(id, {
      height: videoHeight,
      width: videoWidth,
      videoId: vidId,
      events: {
        onReady: onPlayerReady,
      }
    });
  }

  createPlayer();
}

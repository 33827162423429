import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';
import { TweenMax } from '../../common/lib/gsap.js';

import { sendTag } from '../../non-react/at-internet';

const propTypes = {
  question: React.PropTypes.object
};

class Slide extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('scrollToContent', 'showResponse');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.question === this.props.question) {
      return;
    }

    const { content, buttons, scroll } = this.refs;
    TweenMax.set([content, buttons, scroll], {opacity: 0});
    TweenMax.to(content, 0.3, {opacity: 1});
    if (!!buttons) {
      TweenMax.to(buttons, 0.3, {opacity: 1, delay: 0.2});
    }
    TweenMax.to(scroll, 0.3, {opacity: 1, delay: 0.3});
  }

  render() {
    const { question } = this.props;
    const classes = ['slide', question.category ? question.category.c : 'black'];

    let classQuestion = 'between-40-and-60-chars';
    const style = !!question.i ? {
      backgroundImage: `url(${question.i})`
    } : null;

    if (question.t && question.t.length < 40) {
      classQuestion = 'under-40-chars';
    } else if (question.t && question.t.length > 60) {
      classQuestion = 'above-60-chars';
    }

    return (
      <div className="slideset">
        <div className={ classes.join(' ') } >
          <div className="wrap1">
            <div className="wrap2">
              <div className="text" ref="content">
                {question.category && <span className="info">{question.category.t}</span>}
                <h1
                  className={ classQuestion }
                  dangerouslySetInnerHTML={{ __html: question.t }}
                >
                </h1>
                {question.a && <span className="text-info">{question.a}</span>}
                {question.b && <p dangerouslySetInnerHTML={{ __html: question.b }} />}
              </div>
              {(!!question.l || (!!question.cta && !!question.link)) && <div className="btn-holder" ref="buttons">
                <ul className="btn-list">
                  <li>
                    <a href={ question.link || question.l } onClick={ this.showResponse }>{question.cta || 'Voir la réponse'}</a>
                  </li>
                </ul>
              </div>}
              <div className="scroll" ref="scroll">
                <a href="#" className="icon-chevron-down" onClick={this.scrollToContent}>
                  <span className="hidden">icon-down</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {!!question.i && <div className="slide-image" style={style} />}
      </div>
    );
  }

  scrollToContent(event) {
    event.preventDefault();
    const content = document.getElementById('scroll-content');
    TweenMax.to('body, html', 1, {scrollTop: content.offsetTop - 50 });
  }

  showResponse() {
    sendTag('Carrousel_poser_question');
  }
}

Slide.propTypes = propTypes;

export default Slide;

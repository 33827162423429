import {
  CITIES_LOAD,
  CITIES_LOAD_SUCCESS,
} from '../constants/CitiesTypes';
import Config from '../../Config';

export function requestCities() {
  return {
    type: CITIES_LOAD
  };
}

// export function findNearest() {
//   return {
//     type: EVENTS_FIND_NEAREST
//   };
// }

// export function nearestCentered() {
//   return {
//     type: EVENTS_NEAREST_CENTERED
//   };
// }


export function receiveCities(data) {
  // const currentDate = new Date();
  const positions = [];

  const cities = data[0].villes.map((city) => {
    return {
      id: parseInt(city.id, 10),
      title: city.t,
      lat: Number(city.lat),
      lng: Number(city.lng)
    };
  });

  return {
    type: CITIES_LOAD_SUCCESS,
    cities
  };
}

export function fetchCities() {
  return dispatch => {
    dispatch(requestCities());
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(receiveCities(json));
      }
    };
    req.open('GET', Config.URL.CITIES, true);
    req.send();

    return;
  };
}

// export function updateList(ids) {
//   return {
//     type: EVENTS_UPDATE_LIST,
//     list: ids
//   };
// }

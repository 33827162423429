import Isotope from 'isotope-layout';

let isotope;
let element;

function startDocumentsMosaic() {
  if (!Drupal.settings.isRGAA) {
    element = document.getElementsByClassName('document-pro__list')[0];

    if (element) {
      isotope = new Isotope(document.getElementsByClassName('document-pro__list')[0], {
        itemSelector: '.document-pro__item',
        masonry: {
          columnWidth: 0,
          gutter: 0
        },
        transitionDuration: '0s',
      });
    }

    // jQuery('.box a').click(event => {
    //   event.stopPropagation();
    // });

    // jQuery('.box').click(function onBoxClick() {
    //   jQuery(this).find('a')[0].click();
    // });
  }
}

function updateDocumentMosaic() {
  if (!isotope) {
    return;
  }
  isotope.layout();
}

export {
  startDocumentsMosaic,
  updateDocumentMosaic,
};

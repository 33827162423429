import React from 'react';
import { connect } from 'react-redux';

import BaseComponent from '../common/components/BaseComponent';

import { fetchEvents } from '../common/redux/actions/EventsActions';

import EventList from './components/EventList';
import Map from './components/Map';

class Events extends BaseComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchEvents());
  }

  render() {
    const { events, positions, rgaa } = this.props;

    return (
      <div className="map-holder">
        <EventList events={events} />
        <Map markers={positions} rgaa={ rgaa }/>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    events: state.events.events,
    positions: state.events.positions
  };
})(Events);

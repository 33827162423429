import { combineReducers } from 'redux';
import { events } from './Events';
import { questions } from './Questions';
import { search } from './Search';
import { popin } from './Popin';
import { filter } from './Filter';
import { tools } from './Tools';
import { cities } from './Cities';
import { editorial } from './Editorial';

const rootReducer = combineReducers({
  events,
  questions,
  search,
  popin,
  filter,
  tools,
  cities,
  editorial
});
export default rootReducer;

import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Arrow from './caroussel/components/Arrow';
import Caroussel from './caroussel/Caroussel';
import Events from './events/Events';
import ButtonPopin from './button-popin/ButtonPopin';
import Mosaic from './mosaic/Mosaic';
import Tools from './tools/Tools';
import Popin from './button-popin/Popin';
import Search from './search/Search';
import CampaignsFilter from './campaigns-filter/CampaignsFilter';
import Cities from './cities/Cities';

import { SIDE_LEFT, SIDE_RIGHT } from './caroussel/constants/Animation';

import bindSearchButton from './non-react/search-button';
import bindDrupalBehaviorsAddForm from './non-react/add-question-form';
import bindSmoothScroll from './non-react/smooth-scroll';
import addClickOnRelatedBox from './non-react/handle-related-box-click';
import overrideHomeThematicClick from './non-react/home-thematic';
import addOverlay from './non-react/home-overlay';
import initTestimonies from './non-react/testimonies';
import {startIsotope, updateIsotope} from './non-react/home-mosaic';
import {startDocumentsMosaic, updateDocumentMosaic} from './non-react/document-pro-mosaic';
import {startEditorialMosaic, updateEditorialMosaic} from './non-react/editorial-mosaic';
import initKnowledgeCarousel from './non-react/knowledge';
import initToolsCarousel from './non-react/tools';
import updateShareMenuSelection from './non-react/share-position';
import initTac from './non-react/tarte-au-citron';

import store from './common/redux/store';

document.addEventListener('DOMContentLoaded', () => {
  initTac();
  const rgaa = Drupal.settings.isRGAA;

  const isResponse = document.getElementsByClassName('node-type-question-reponse').length !== 0;
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (isResponse && currentScroll === 0 && jQuery(window).width() > 992) {
    jQuery('html, body').animate({ scrollTop: jQuery('#header').innerHeight() });
  }

  if (!rgaa) {
    const carousselDOM = document.getElementById('carousel-react');
    if (carousselDOM) {
      ReactDOM.render(
        <Provider store={ store }>
          <Caroussel />
        </Provider>,
        carousselDOM);
    }

    document.querySelectorAll('.react-next').forEach(element => {
      ReactDOM.render(
        <Arrow
          available
          color="color-white"
          isMainCarousel={false}
          side={SIDE_RIGHT}
        />,
        element
      );
    });

    document.querySelectorAll('.react-previous').forEach(element => {
      ReactDOM.render(
        <Arrow
          available
          color="color-white"
          isMainCarousel={false}
          side={SIDE_LEFT}
        />,
        element
      );
    });

    const campaignsFilter = document.getElementById('filters-wrapper');
    if (campaignsFilter) {
      ReactDOM.render(
          <Provider store={ store }>
            <CampaignsFilter />
          </Provider>,
          campaignsFilter);
    }

    const searchDOM = document.getElementById('search-react');
    if (searchDOM) {
      ReactDOM.render(
          <Provider store={ store }>
            <Search />
          </Provider>,
          searchDOM);
    }

    const mosaicDOM = document.getElementById('mosaic-react');
    if (mosaicDOM && !Drupal.settings.isFront) {
      ReactDOM.render(
        <Provider store={ store }>
          <Mosaic />
        </Provider>,
        mosaicDOM);
    }

    const toolsDOM = document.getElementById('tools-react');
    if (toolsDOM && !Drupal.settings.isFront) {
      ReactDOM.render(
        <Provider store={ store }>
          <Tools />
        </Provider>,
        toolsDOM);
    }

    bindSearchButton();
  }

  const popinTools = document.getElementsByClassName('popin-tools');
  for (let i = 0; i < popinTools.length; i++) {
    if (popinTools[i]) {
      ReactDOM.render(
          <Provider store={ store }>
            <ButtonPopin
              type={popinTools[i].getAttribute('data-type')}
              content={ popinTools[i].getAttribute('data-content') }
              image={ popinTools[i].getAttribute('data-image') }
            />
          </Provider>,
          popinTools[i]);
    }
  }


  const transcripts = document.getElementsByClassName('transcript-wrapper');
  for (let i = 0; i < transcripts.length; i++) {
    if (transcripts[i]) {
      ReactDOM.render(
          <Provider store={ store }>
            <ButtonPopin
              type="transcript"
              content={ transcripts[i].innerHTML }
            />
          </Provider>,
          transcripts[i]);
    }
  }

  const images = document.getElementsByClassName('campain');
  for (let i = 0; i < images.length; i++) {
    if (images[i]) {
      const zoomWrapper = images[i].getElementsByClassName('zoom-wrapper')[0];
      const img = images[i].getElementsByTagName('img')[0];
      ReactDOM.render(
          <Provider store={ store }>
            <ButtonPopin
              type="image"
              content={ zoomWrapper.getAttribute('data-content') }
              animateFrom={ img }
            />
          </Provider>,
          zoomWrapper);
    }
  }

  const eventsDOM = document.getElementsByClassName('map-holder');
  if (eventsDOM.length === 1) {
    ReactDOM.render(
      <Provider store={ store }>
        <Events
          rgaa={ rgaa }
        />
      </Provider>,
      eventsDOM[0]);
  }

  const citiesDOM = document.getElementsByClassName('map-holder-ambassador');
  if (citiesDOM.length === 1) {
    ReactDOM.render(
      <Provider store={ store }>
        <Cities
          rgaa={ rgaa }
        />
      </Provider>,
      citiesDOM[0]);
  }

  const popinDOM = document.getElementById('popin-media');
  if (popinDOM) {
    ReactDOM.render(
      <Provider store={ store }>
        <Popin />
      </Provider>,
      popinDOM);
  }

  bindDrupalBehaviorsAddForm();
  bindSmoothScroll();
  addClickOnRelatedBox();
  addOverlay();
  updateShareMenuSelection();
  if (!rgaa) {
    initKnowledgeCarousel();
    initToolsCarousel();
    startIsotope();
    startDocumentsMosaic();
    overrideHomeThematicClick();
    startEditorialMosaic();
    initTestimonies();
  }
});

window.addEventListener('load', () => {
  const rgaa = Drupal.settings.isRGAA;

  if (!rgaa) {
    updateIsotope();
    updateDocumentMosaic();
    updateEditorialMosaic();
  }
});

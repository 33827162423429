import {
  SEARCH_HIDE,
  SEARCH_LOAD,
  SEARCH_LOAD_SUCCESS,
  SEARCH_LOAD_ERROR,
  SEARCH_SHOW
} from '../constants/SearchTypes';

const initState = {
  hasSearched: false,
  isFetching: false,
  isVisible: false,
  results: []
};

export function search(state = initState, action) {
  switch (action.type) {
    case SEARCH_HIDE: {
      return {
        ...state,
        hasSearched: false,
        isFetching: false,
        isVisible: false,
        results: []
      };
    }
    case SEARCH_LOAD: {
      return {
        ...state,
        hasSearched: false,
        isFetching: true
      };
    }
    case SEARCH_LOAD_SUCCESS: {
      return {
        ...state,
        hasSearched: true,
        isFetching: false,
        results: action.results
      };
    }
    case SEARCH_LOAD_ERROR: {
      return {
        ...state,
        hasSearched: true,
        isFetching: false
      };
    }
    case SEARCH_SHOW: {
      return {
        ...state,
        isFetching: false,
        isVisible: true,
        results: []
      };
    }
    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import debounce from 'debounce';
import MarkerClusterer from '../../../lib/markerclusterer';
import scriptjs from 'scriptjs';

import BaseComponent from '../../common/components/BaseComponent';

import { nearestCentered, updateList } from '../../common/redux/actions/EventsActions';
import store from '../../common/redux/store';

import Config from '../../common/Config';
import Style from '../style/Map';

let map;
let markerCluster;
const markers = [];

class Map extends BaseComponent {

  constructor(props) {
    super(props);

    this.debounced = debounce(this.onBoundsChanged, 200);

    this.state = {
      isLoaded: false
    };
  }

  componentWillUpdate(nextProps) {
    if (Drupal.settings.isRGAA) {
      return;
    }

    const { dispatch } = this.props;
    let minDistance = Math.pow(99, 99);
    let selectedItem;
    if (nextProps.findNearest === true) {
      markers.forEach((item) => {
        const currentDistanceFromCenter = google.maps.geometry.spherical
          .computeDistanceBetween(item.position, map.getCenter());
        if (currentDistanceFromCenter < minDistance) {
          selectedItem = item;
          minDistance = currentDistanceFromCenter;
        }
      });
      if (selectedItem) {
        map.panTo(selectedItem.position);
        map.setZoom(14);
      }

      dispatch(nearestCentered());
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.markers && markers.length === 0) {
      this.createMarkers();
    }
  }

  componentWillMount() {
    if (window.innerWidth < 767) {
      return;
    }

    scriptjs(Config.EVENTS.GOOGLE_API, () => {
      this.setState({ isLoaded: true });

      const mapOptions = {
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER
        },
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'custom']
        },
        streetViewControl: false,
      };

      if (Drupal.settings.isRGAA) {
        Object.assign(mapOptions, {
          clickableIcons: false,
          disableDoubleClickZoom: true,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          disableDefaultUI: true
        });
      }

      map = new google.maps.Map(ReactDOM.findDOMNode(this), mapOptions);
      console.log(mapOptions);
      const custom = new google.maps.StyledMapType(Style);
      map.mapTypes.set('custom', custom);
      map.setMapTypeId('custom');

      map.fitBounds(new google.maps.LatLngBounds(Config.EVENTS.BOUNDS.SW, Config.EVENTS.BOUNDS.NE));

      this.createMarkers();
    });
  }

  render() {
    return (
      <div className="map"></div>
    );
  }

  createMarkers() {
    let markerPath = Config.EVENTS.MARKER;
    const clusterConf = Config.EVENTS.CLUSTERER;

    if (this.props.markers.length === 0 || !map || markers.length !== 0) {
      return;
    }

    if (this.props.rgaa) {
      markerPath = Config.EVENTS.BW_MARKER;
      clusterConf.styles[0].url = markerPath;
      clusterConf.zoomOnClick = false;
    }

    const icon = {
      url: markerPath,
      size: new google.maps.Size(104, 130),
      anchor: new google.maps.Point(52 / 2, 65 - 4),
      scaledSize: new google.maps.Size(52, 65)
    };

    this.props.markers.forEach((position) => {
      const marker = new google.maps.Marker({
        id: position.id,
        position: {
          lat: position.lat,
          lng: position.lng
        },
        map,
        icon
      });


      if (!Drupal.settings.isRGAA) {
        marker.addListener('click', () => {
          if (map.getZoom() < 12) map.setZoom(12);
          map.setCenter(marker.getPosition());
        });
      }

      markers.push(marker);
    });

    markerCluster = new MarkerClusterer(map, markers, clusterConf);

    google.maps.event.addListener(map, 'bounds_changed', this.debounced);
  }

  onBoundsChanged() {
    if (Drupal.settings.isRGAA) {
      return;
    }

    const bounds = map.getBounds();
    const positionIds = markers
      .filter((marker) => {
        return bounds.contains(marker.getPosition());
      })
      .map((marker) => marker.id);
    store.dispatch(updateList(positionIds));
  }
}

export default connect((state) => {
  return {
    findNearest: state.events.findNearest
  };
})(Map);

import React from 'react';
import { connect } from 'react-redux';
import debounce from 'debounce';
import Isotope from 'isotope-layout';

import BaseComponent from '../../common/components/BaseComponent';
import { findNearest } from '../../common/redux/actions/EventsActions';

import Event from './Event';

class EventList extends BaseComponent {

  constructor(props) {
    super(props);

    this.debounced = debounce(this.resized, 200);

    this._bind('debounced', 'findNearest');

    this.state = {
      isEmpty: false,
      shouldUpdate: true
    };
  }

  componentDidUpdate() {
    if (!this.isotope && this.props.events) {
      this.isotope = new Isotope(this.refs.events, {
        itemSelector: '.event',
        masonry: {
          columnWidth: 0,
          gutter: 0
        },
        transitionDuration: '0s'
      });
      this.isotope.on('arrangeComplete', (filteredItems) => {
        const { shouldUpdate } = this.state;
        if (shouldUpdate) {
          this.setState({
            isEmpty: filteredItems.length === 0,
            shouldUpdate: false
          });
        }
      });
    }

    this.debounced();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.list !== this.props.list) {
      this.setState({
        shouldUpdate: true
      });
    }
  }

  render() {
    const { events } = this.props;
    const { isEmpty } = this.state;

    let findNearestButton;
    if (isEmpty) {
      findNearestButton = (
        <div className="no-event-info">
          <span>il n’y a pas d’évenements<br /> dans cette zone.</span>
          <a href="#" className="btn" onClick={this.findNearest}>voir l’événement le plus proche</a>
        </div>
      );
    }

    return (
      <div className="events-block">
        <h1>Événements</h1>
        <div className="events-container" ref="events">
          { events.map((event, ukey) => {
            return (
              <Event data={ event } key={ukey} resized={ this.debounced }/>
            );
          })}
        </div>
        { findNearestButton }
      </div>
    );
  }

  resized() {
    const { list } = this.props;

    this.isotope.options.transitionDuration = '0.4s';
    this.isotope.arrange({
      isJQueryFiltering: false,
      filter: (item) => {
        let isVisible = true;
        const positionId = parseInt(item.getAttribute('data-position-id'), 10);
        if (list) {
          isVisible = list.indexOf(positionId) !== -1;
        }

        return isVisible;
      }
    });
  }

  findNearest(event) {
    event.preventDefault();
    const { dispatch } = this.props;

    dispatch(findNearest());
  }
}

export default connect((state) => {
  return {
    list: state.events.list,
  };
})(EventList);

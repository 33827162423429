function addClickListener(box) {
  const questionLink = box.getElementsByClassName('btn')[0].getAttribute('href');
  box.getElementsByClassName('text')[0].addEventListener(
      'click', () => { window.location = questionLink; }, false);
}

export default function addClickOnRelatedBox() {
  if (document.getElementsByClassName('node-type-question-reponse').length === 0) {
    return;
  }

  const sidebar = document.getElementById('sidebar');
  const boxes = sidebar.getElementsByClassName('box');

  for (let i = 0; i < boxes.length; i++) {
    addClickListener(boxes[i]);
  }
}

import { TweenMax } from '../common/lib/gsap.js';

export default function bindSmoothScroll() {
  jQuery('.js-smooth-scroll').click(function onSmoothScrollClick(event) {
    jQuery('body').removeClass('nav-active');
    const target = jQuery(`#${jQuery(this).data('target-id')}`);
    if (target && target.offset()) {
      event.preventDefault();
      TweenMax.to('body, html', 1, {scrollTop: target.offset().top - 50 });
    }
  });
}

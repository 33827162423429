import {
  CLOSE_ALL,
  FILTER_OPEN,
  FILTER_CLOSE,
  SORTER_OPEN,
  SORTER_CLOSE
} from '../constants/FilterTypes';

export function showFilter() {
  document.body.classList.add('filter-state');
  return {
    type: FILTER_OPEN
  };
}

export function hideFilter() {
  setTimeout(() => jQuery(window).trigger('scroll'), 20);
  document.body.classList.remove('filter-state');
  return {
    type: FILTER_CLOSE
  };
}

export function showSorter() {
  document.body.classList.add('sorter-state');
  return {
    type: SORTER_OPEN
  };
}

export function hideSorter() {
  document.body.classList.remove('sorter-state');
  return {
    type: SORTER_CLOSE
  };
}

export function closeAll() {
  return {
    type: CLOSE_ALL
  };
}

import {
  EDITORIAL_LOAD,
  EDITORIAL_LOAD_SUCCESS,
} from '../constants/EditorialTypes';
import Config from '../../Config';

export function requestEditorial() {
  return {
    type: EDITORIAL_LOAD
  };
}

// export function findNearest() {
//   return {
//     type: EVENTS_FIND_NEAREST
//   };
// }

// export function nearestCentered() {
//   return {
//     type: EVENTS_NEAREST_CENTERED
//   };
// }


export function receiveEditorial(data) {
  const blocks = data[0].blocks.map((block) => {
    return {
      title: block.t,
      description: block.d,
      ctaText: block.lt,
      ctaUrl: block.lu
    };
  });

  return {
    type: EDITORIAL_LOAD_SUCCESS,
    blocks
  };
}

export function fetchEditorial() {
  return dispatch => {
    dispatch(requestEditorial());
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(receiveEditorial(json));
      }
    };
    req.open('GET', Config.URL.EDITORIAL, true);
    req.send();

    return;
  };
}

import { DATA_LOAD, DATA_LOAD_SUCCESS } from '../constants/DataTypes';
import Config from '../../Config';

export function requestData() {
  return {
    type: DATA_LOAD
  };
}

export function receiveData(data) {
  const autostart = data[0].config.carousel_autostart;
  const categories = data[0].categories;
  const questions = data[0].questions;
  const carousel = data[0].carousel;
  const pagination = parseInt(data[0].config.pagination, 10);

  questions.forEach((question) => {
    const currentCategory = categories.find(
      category => parseInt(category.id, 10) === parseInt(question.c, 10)
    );
    Object.assign(question, {
      category: {
        t: currentCategory.t,
        c: currentCategory.c
      },
      t: question.t.replace(/\s\?/g, '&nbsp;?').replace(/^\s+|\s+$/g, '')
    });
    if (!currentCategory.questions) {
      currentCategory.questions = [];
    }
    currentCategory.questions.push(question.id);
  });

  // If user was redirected on homepage by clicking share position button
  // Scrool to share position section, whant data loaded
  if (window.location.hash && window.location.hash === '#share-position') {
    setTimeout(() => jQuery('a[href="/#share-position"]').trigger('click'), 1000);
  }

  return {
    type: DATA_LOAD_SUCCESS,
    autostart,
    carousel,
    categories,
    questions,
    pagination
  };
}

export function fetchData() {
  return dispatch => {
    dispatch(requestData());
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(receiveData(json));
      }
    };
    req.open('GET', Config.URL.DATA, true);
    req.send();

    return;
  };
}

export function shouldRequest(state) {
  if (state.questions.isFetching) {
    return false;
  } else if (state.questions.carousel.length !== 0) {
    return false;
  }
  return true;
}

export function requestDataIfNeeded() {
  return (dispatch, getState) => {
    if (shouldRequest(getState())) {
      return dispatch(fetchData());
    }
  };
}

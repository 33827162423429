/* eslint-disable spaced-comment */
import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'cookies-js';
import Isotope from 'isotope-layout';

import BaseComponent from '../common/components/BaseComponent';
import { TweenMax } from '../common/lib/gsap.js';
import { fetchTools } from '../common/redux/actions/ToolsActions';

import Filters from './components/Filters';
import Pagination from './components/Pagination';
import Tool from './components/Tool';


class Tools extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('updateFilter', 'resized', 'selectPage');

    const cookieFiltersThemes = Cookies.get('filtertools_themes');
    const cookieFiltersTypes = Cookies.get('filtertools_types');
    const initFilters = [];

    if (cookieFiltersThemes) {
      initFilters.themes = cookieFiltersThemes.split(',').map((id) => parseInt(id, 10));
    }

    if (cookieFiltersTypes) {
      initFilters.types = cookieFiltersTypes.split(',').map((id) => parseInt(id, 10));
    }

    this.state = {
      filters: initFilters,
      currentPage: parseInt(Cookies.get('pagetools'), 10) || 0,
      shouldUpdate: true,
      totalResults: 0,
      transitionDuration: 'Os',
    };
  }


  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchTools());
  }


  componentDidUpdate(prevProps, prevState) {
    if (!this.isotope && this.props.tools) {
      this.isotope = new Isotope(this.refs.mosaictools, {
        getSortData: {
          id: function getId(itemElem) {
            return parseInt(jQuery(itemElem).data('id'), 10);
          }
        },
        itemSelector: '.tools-item',
        masonry: {
          columnWidth: 0,
          gutter: 0
        },
        sortAscending: false,
        transitionDuration: '0s'
      });
      this.isotope.on('arrangeComplete', (filteredItems) => {
        const { shouldUpdate } = this.state;
        if (shouldUpdate) {
          this.setState({
            shouldUpdate: false
          });
        }

        filteredItems.forEach((item) => {
          const currentItem = item;
          if (currentItem.element.style.visibility !== 'hidden') {
            return;
          }

          currentItem.element.style.visibility = 'visible';
          currentItem.element.style.opacity = 0;

          jQuery(currentItem.element).animate({opacity: 1});
        });
      });
    }

    if (this.state.shouldUpdate) {
      setTimeout(this.resized, 200);
    }
  }


  render() {
    const { tools, step } = this.props;
    const { currentPage, filters, totalResults, view } = this.state;
    const classNoResult = ['tools-mosaique__noresult'];
    if (totalResults <= 0) {
      classNoResult.push('is-show');
    }

    return (
      <div className="tools">
        <div className="tools__wrapper">
          <div className="tools__container">
            <Filters filters={filters} updateFilter={this.updateFilter}/>
            <div className="tools-mosaique" ref="mosaictools">
              { tools.map((tool, ukey) =>
                (<Tool
                  data={tool}
                  key={ukey}
                  resized={this.resized}
                />)
              )}
            </div>
            <p className={ classNoResult.join(' ')}>Aucun outil de communication ne correspond aux filtres sélectionnés</p>
          </div>
          <Pagination current={currentPage} onPageChange={this.selectPage} step={step} total={totalResults} />
        </div>
      </div>
    );
  }

  selectPage(page) {
    const { mosaictools } = this.refs;

    const target = jQuery('#tools-react');

    if (target && target.offset()) {
      TweenMax.to('body, html', 0.5, { scrollTop: target.offset().top - 50 });
    }

    Cookies.set('pagetools', page);
    this.setState({
      currentPage: page,
      shouldUpdate: true,
      transitionDuration: '0.4s'
    });
  }

  resized() {
    const { tools, step } = this.props;
    const { filters, currentPage, transitionDuration } = this.state;
    const minElements = currentPage * step;
    const maxElements = (currentPage + 1) * step;
    let itemsFiltered = tools;

    if (filters.types) {
      if (filters.types.length > 0) {
        itemsFiltered = itemsFiltered
        .filter(tool => filters.types.includes(parseInt(tool.type.id, 10)));
      }
    }

    if (filters.themes) {
      if (filters.themes.length > 0) {
        itemsFiltered = itemsFiltered
        .filter(tool => filters.themes.includes(parseInt(tool.thematique.id, 10)));
      }
    }

    const itemsToDisplay = itemsFiltered
      .slice(minElements, maxElements)
      .map(tool => parseInt(tool.id, 10));

    this.isotope.options.transitionDuration = transitionDuration;
    this.isotope.arrange({
      isJQueryFiltering: false,
      filter: (item) => itemsToDisplay.indexOf(parseInt(item.getAttribute('data-id'), 10)) !== -1
    });

    this.setState({
      totalResults: itemsFiltered.length
    });
  }

  updateFilter(values, group) {
    const { filters } = this.state;
    const newFilters = filters;

    if (values) {
      newFilters[`${group}`] = values;
    } else {
      newFilters[`${group}`] = [];
    }

    Cookies.set(`filtertools_${group}`, newFilters[group]);
    Cookies.set('pagetools', 0);

    this.setState({
      filters: newFilters,
      currentPage: 0,
      shouldUpdate: true,
      transitionDuration: '0.4s'
    });
  }
}

export default connect((state) => {
  return {
    tools: state.tools.tools,
    step: state.tools.step
  };
})(Tools);

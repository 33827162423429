import React from 'react';
import { connect } from 'react-redux';
import MainComponent from '../common/components/MainComponent';
import { show } from '../common/redux/actions/PopinActions';

const propTypes = {
  type: React.PropTypes.string.isRequired,
  content: React.PropTypes.string,
  animateFrom: React.PropTypes.any
};

class ButtonPopin extends MainComponent {
  constructor(props) {
    super(props);
    this._bind('onClickHandler');
    this.state = {
      isOpen: false
    };
  }

  render() {
    const { type, image } = this.props;
    let label;
    let classNames;

    switch (type) {
      case 'transcript':
        label = 'Lire la transcription';
        classNames = 'transcript';
        break;
      case 'image':
        label = 'Agrandir';
        classNames = 'enlarge';
        break;
      case 'file':
        label = 'Agrandir';
        classNames = 'enlarge';
        break;
      case 'video':
        label = this.renderImage(image);
        classNames = '';
    }

    return (
      <a href="#" className={ classNames } onClick={this.onClickHandler}>
        { label }
      </a>
    );
  }


  renderImage(url) {
    // const { styles } = this.state;

    return (
      <div className="tools-item__img">
        <picture>
          <img src={ url }/>
        </picture>
      </div>
    );
  }

  onClickHandler(event) {
    event.preventDefault();
    const { type, content, animateFrom } = this.props;
    this.props.dispatch(show(type, content, animateFrom));
  }
}

ButtonPopin.propTypes = propTypes;

export default connect((state) => {
  return {
    isOpen: state.isOpen
  };
})(ButtonPopin);

import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  list: React.PropTypes.array.isRequired
};

class Results extends BaseComponent {

  render() {
    const { list } = this.props;

    return (
      <div className="questions-list-holder">
        <ul className="question-list">
          {list.slice(0, 5).map((item, ukey) => (
              <li key={ukey}>
                <a href={item.l} dangerouslySetInnerHTML={{ __html: item.t }}></a>
              </li>
            )
          )}
        </ul>
      </div>
    );
  }
}

Results.propTypes = propTypes;

export default Results;

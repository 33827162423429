import React, { Component, PropTypes } from 'react';
import BaseComponent from '../../common/components/BaseComponent';
import Checkbox from './Checkbox';

class Filter extends BaseComponent {

  constructor(props) {
    super(props);

    this._bind('toggleCheckbox');
  }

  componentWillMount() {
    const { filters } = this.props;
    this.selectedCheckboxes = new Set(filters);
  }

  toggleCheckbox(id) {
    const { getValues, name } = this.props;
    const idCheckbox = parseInt(id, 10);
    if (this.selectedCheckboxes.has(idCheckbox)) {
      this.selectedCheckboxes.delete(idCheckbox);
    } else {
      this.selectedCheckboxes.add(idCheckbox);
    }

    getValues(this.selectedCheckboxes, name);
  }

  render() {
    const { title, items, filters } = this.props;

    return (
      <div className="tools-filters__wrap">
          <div className="tools-filters__title">{title}</div>
            <ul className="tools-filters__list" data-filter-group="themes">
            { items.map((item, ukey) => {
              const key = `thematique-${ukey}`;
              return (
                  <li className="selected tools-filters__item" key={key} >
                    <Checkbox
                      label={item.n}
                      handleCheckboxChange={this.toggleCheckbox}
                      value={item.tid}
                      key={key}
                      filters={filters}
                    />
                  </li>
                );
            })}
            </ul>
      </div>
    );
  }
}

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  toggleCheckbox: PropTypes.func,
};

export default Filter;

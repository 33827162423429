import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import MarkerClusterer from '../../../lib/markerclusterer';
import scriptjs from 'scriptjs';

import BaseComponent from '../../common/components/BaseComponent';

import { nearestCentered, updateList } from '../../common/redux/actions/EventsActions';

import Config from '../../common/Config';
import Style from '../style/Map';

let map;
let markerCluster;
const markers = [];

class Map extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };
  }

  componentWillUpdate(nextProps) {
    if (Drupal.settings.isRGAA) {
      return;
    }

    const { dispatch } = this.props;
    let minDistance = Math.pow(99, 99);
    let selectedItem;
    if (nextProps.findNearest === true) {
      markers.forEach((item) => {
        const currentDistanceFromCenter = google.maps.geometry.spherical
          .computeDistanceBetween(item.position, map.getCenter());
        if (currentDistanceFromCenter < minDistance) {
          selectedItem = item;
          minDistance = currentDistanceFromCenter;
        }
      });
      if (selectedItem) {
        map.panTo(selectedItem.position);
        map.setZoom(14);
      }

      dispatch(nearestCentered());
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.markers && markers.length === 0) {
      this.createMarkers();
    }
  }

  componentWillMount() {
    scriptjs(Config.EVENTS.GOOGLE_API, () => {
      this.setState({ isLoaded: true });

      const mapOptions = {
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER
        },
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'custom']
        },
        streetViewControl: false,
        scrollwheel: true,
      };

      if (Drupal.settings.isRGAA) {
        Object.assign(mapOptions, {
          clickableIcons: false,
          disableDoubleClickZoom: true,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          disableDefaultUI: true
        });
      }

      map = new google.maps.Map(ReactDOM.findDOMNode(this), mapOptions);
      const custom = new google.maps.StyledMapType(Style);
      map.mapTypes.set('custom', custom);
      map.setMapTypeId('custom');

      map.fitBounds(new google.maps.LatLngBounds(Config.AMBASSADORS.BOUNDS.SW, Config.AMBASSADORS.BOUNDS.NE));

      this.createMarkers();
    });
  }

  render() {
    return (
      <div className="ambassador-map"></div>
    );
  }

  createMarkers() {
    let markerPath = Config.AMBASSADORS.MARKER;
    const clusterConf = Config.AMBASSADORS.CLUSTERER;

    if (this.props.markers.length === 0 || !map || markers.length !== 0) {
      return;
    }

    if (this.props.rgaa) {
      markerPath = Config.EVENTS.BW_MARKER;
      clusterConf.styles[0].url = markerPath;
      clusterConf.zoomOnClick = false;
    }

    const icon = {
      url: markerPath,
      size: new google.maps.Size(104, 130),
      anchor: new google.maps.Point(52 / 2, 42 - 4),
      scaledSize: new google.maps.Size(52, 42)
    };

    const infowindow = new google.maps.InfoWindow();
    let markerOpen;

    this.props.markers.forEach((position) => {
      const marker = new google.maps.Marker({
        id: position.id,
        position: {
          lat: position.lat,
          lng: position.lng
        },
        map,
        icon
      });

      if (!Drupal.settings.isRGAA) {
        marker.addListener('click', () => {
          if (markerOpen === marker.id) {
            infowindow.close();
            markerOpen = null;
          } else {
            infowindow.setOptions({
              content: position.title,
              // pixelOffset: new google.maps.Size(26, 21)
            });
            infowindow.open(map, marker);
            markerOpen = marker.id;
          }
        });
      }

      markers.push(marker);
    });

    markerCluster = new MarkerClusterer(map, markers, clusterConf);
  }
}

export default connect((state) => {
  return {
    findNearest: state.events.findNearest
  };
})(Map);

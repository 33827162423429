import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import createLogger from 'redux-logger';
import rootReducer from './reducers';

let middleware = [];
middleware = [...middleware, thunk];
// middleware = [...middleware, createLogger()];


const finalCreateStore = compose(
  applyMiddleware(...middleware)
)(createStore);

const store = finalCreateStore(rootReducer);

export default store;

import {
  EVENTS_LOAD,
  EVENTS_LOAD_SUCCESS,
  EVENTS_LOAD_ERROR,
  EVENTS_UPDATE_LIST,
  EVENTS_FIND_NEAREST,
  EVENTS_NEAREST_CENTERED
} from '../constants/EventsTypes';

const initState = {
  events: [],
  positions: [],
  findNearest: false,
  isFetching: false,
  list: null
};

export function events(state = initState, action) {
  switch (action.type) {
    case EVENTS_LOAD: {
      return {
        ...state,
        isFetching: true
      };
    }
    case EVENTS_LOAD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        events: action.events,
        positions: action.positions
      };
    }
    case EVENTS_LOAD_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    case EVENTS_UPDATE_LIST: {
      return {
        ...state,
        list: action.list
      };
    }
    case EVENTS_FIND_NEAREST: {
      return {
        ...state,
        findNearest: true
      };
    }
    case EVENTS_NEAREST_CENTERED: {
      return {
        ...state,
        findNearest: false
      };
    }
    default:
      return state;
  }
}

import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';

import { sendTag } from '../../non-react/at-internet';

const propTypes = {
  onChange: React.PropTypes.func.isRequired,
  onFocus: React.PropTypes.func.isRequired
};

class Form extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };

    this._bind('onFocus', 'onKeyUp', 'onSubmit');
  }

  render() {
    return (
      <form className="search-form" action="#" onSubmit={this.onSubmit}>
        <fieldset>
          <legend className="hidden">search form</legend>
          <div className="row">
            <label htmlFor="search" className="hidden">search</label>
            <input
              id="search"
              type="text"
              autoComplete="off"
              placeholder="Quelle est votre question ?"
              ref="search"
              onFocus={this.onFocus}
              onKeyUp={this.onKeyUp}
            />
            <a href="#" className="icon-loupe">
              <span className="hidden">icon-loupe</span>
            </a>
            <input type="submit" className="hidden" value="submit" />
          </div>
        </fieldset>
      </form>
    );
  }
  /*
  onClear(event) {
    event.preventDefault();

    const { search } = this.refs;
    const { dispatch, onChange } = this.props;
    search.value = '';
    onChange('');
    dispatch(searchHide());
  }
  */
  onFocus() {
    sendTag('Champ_recherche');
    this.props.onFocus();
  }

  onSubmit(event) {
    event.preventDefault();
  }

  onKeyUp(event) {
    const { value } = this.state;
    const { onChange } = this.props;
    event.preventDefault();

    if (event.target.value !== value) {
      this.setState({value: event.target.value});
      onChange(event.target.value);
    }
  }
}

Form.propTypes = propTypes;

export default Form;

import { TOOLS_LOAD, TOOLS_LOAD_SUCCESS, TOOLS_LOAD_ERROR } from '../constants/ToolsTypes';

const initState = {
  isFetching: false,
  types: [],
  thematiques: [],
  tools: [],
  step: null
};

export function tools(state = initState, action) {
  switch (action.type) {
    case TOOLS_LOAD: {
      return {
        ...state,
        isFetching: true
      };
    }
    case TOOLS_LOAD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        // autostart: action.autostart,
        types: action.types,
        thematiques: action.thematiques,
        tools: action.tools,
        step: action.pagination
      };
    }
    case TOOLS_LOAD_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    default:
      return state;
  }
}

import { sendTag } from './at-internet';

export default function bindDrupalBehaviorsAddForm() {
  // Called before form insertion by drupal ajax
  Drupal.ajax.prototype.commands.ddoAddQuestionForm = function ddoAddQuestionForm() {
    const { body } = document;

    if (document.getElementById('form-content')) {
      return;
    }

    const topWrap = document.createElement('div');
    topWrap.setAttribute('class', 'top-wrap');

    const closeBtn = document.createElement('a');
    const closeValue = document.createTextNode('FERMER');
    closeBtn.classList.add('close-btn');
    closeBtn.setAttribute('href', '#');
    closeBtn.appendChild(closeValue);
    topWrap.appendChild(closeBtn);

    document.getElementById('search-react').classList.add('mobile-form');
    document.getElementById('search-react').appendChild(topWrap);

    const formContent = document.createElement('div');
    formContent.setAttribute('id', 'form-content');
    document.getElementById('search-react').appendChild(formContent);

    body.classList.add('questions-page');
    body.classList.remove('nav-active');

    jQuery('.close-btn').click(() => {
      jQuery('#search-react').empty();

      body.classList.remove('questions-page');
      document.getElementById('search-react').classList.remove('mobile-form');
    });
  };

  Drupal.ajax.prototype.commands.ddoAddedQuestionForm = function ddoAddQuestionForm() {
    if (Drupal.currentSearch) {
      document.getElementById('edit-title').value = Drupal.currentSearch;
      sendTag(`Recherche_bouton_poser_question::${Drupal.currentSearch}`);
    }
  };

  Drupal.ajax.prototype.commands.ddoSendedQuestionForm = function ddoSendedQuestionForm() {
    sendTag('Bouton_poser_question');
  };
}

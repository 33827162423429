import React from 'react';
import { connect } from 'react-redux';
import { closeAll } from '../../common/redux/actions/FilterAction';

import BaseComponent from '../../common/components/BaseComponent';

const propTypes = {
  current: React.PropTypes.string.isRequired,
  onViewChange: React.PropTypes.func.isRequired,
};

class View extends BaseComponent {
  constructor(props) {
    super(props);

    this._bind('handleClick');
  }

  handleClick(event) {
    const view = event.target.getAttribute('value');
    this.props.dispatch(closeAll());
    if (view !== this.props.current) {
      this.props.onViewChange(view);
    }
  }

  render() {
    const options = ['box', 'line'];
    return (
      <div className="view-select">
        <legend>Afficher</legend>
        <ul>
          {
            options.map((option, index) => {
              const checked = this.props.current === option;
              const className = checked ? 'selected' : '';
              return (
                <li key={index} className={className}>
                  <input onClick={this.handleClick} type="radio" id={option} name="view" value={option} checked={checked} />
                  <label htmlFor={option}><i className={`ico-${option}`} /><span>{option}</span></label>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}

export default connect()(View);

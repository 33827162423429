import React from 'react';
import { connect } from 'react-redux';
import MainComponent from '../common/components/MainComponent';

import { sendTag } from '../non-react/at-internet';

class CampaignsFilter extends MainComponent {
  constructor(props) {
    super(props);
    this._bind('handleClickFilter');
    this.state = {
      activeFilter: 1
    };
  }

  componentWillMount() {
    const groupPostMortem = document.getElementsByClassName('group-don-post-mortem')[0];
    const groupVivant = document.getElementsByClassName('group-don-vivant')[0];

    switch (window.location.hash) {
      case '#ledonpostmortem':
        this.setState({ activeFilter: 1 });
        break;
      case '#ledonduvivant':
        this.setState({ activeFilter: 2 });
        break;
    }

    if (groupVivant) {
      groupVivant.firstChild.className += ' group-invisible';
    }

    if (groupPostMortem) {
      groupPostMortem.firstChild.className += ' group-invisible';
    }
  }

  render() {
    const { activeFilter } = this.state;
    const groupPostMortem = document.getElementsByClassName('group-don-post-mortem')[0];
    const groupVivant = document.getElementsByClassName('group-don-vivant')[0];
    let labelShown = true;

    let firstLiClass;
    let firstAClass;
    let secondLiClass;
    let secondAClass;

    if (groupVivant && groupPostMortem) {
      if (activeFilter === 1) {
        firstLiClass = 'active';
        firstAClass = 'gray';
        secondLiClass = '';
        secondAClass = 'gray1';

        groupVivant.className += ' group-invisible';
        groupPostMortem.className = 'group-don-post-mortem field-group-fieldset';
      } else {
        firstLiClass = '';
        firstAClass = 'gray1';
        secondLiClass = 'active';
        secondAClass = 'gray';

        groupVivant.className = 'group-don-vivant field-group-fieldset';
        groupPostMortem.className += ' group-invisible';
      }
    } else if (groupPostMortem) {
      firstLiClass = 'active';
      firstAClass = 'gray';
      secondLiClass = 'tab-invisible';
      secondAClass = 'gray1';

      labelShown = false;
      groupPostMortem.className = 'group-don-post-mortem field-group-fieldset';
    } else if (groupVivant) {
      firstLiClass = 'tab-invisible';
      firstAClass = 'gray1';
      secondLiClass = 'active';
      secondAClass = 'gray';

      labelShown = false;
      groupVivant.className = 'group-don-vivant field-group-fieldset';
    }

    return (
      <ul className="filter" id="scroll3">
        <li>
          <span className={ labelShown ? '' : 'label-invisible' }>filtrer par</span>
        </li>
        <li className={ firstLiClass }>
          <a
            href="#ledonpostmortem"
            className={ firstAClass }
            id="1"
            onClick={ this.handleClickFilter }
          >
            Le don post mortem
          </a>
        </li>
        <li className={ secondLiClass }>
          <a
            href="#ledonduvivant"
            className={ secondAClass }
            id="2"
            onClick={ this.handleClickFilter }
          >
            Le don du vivant
          </a>
        </li>
      </ul>
    );
  }

  handleClickFilter(e) {
    let date = '';
    const domCurrent = document.getElementsByClassName('current');
    if (domCurrent.length !== 0) {
      date = domCurrent[0].textContent;
    }

    if (e.target.id === '1') {
      sendTag(`Campagne_${date}_post_mortem`);
      this.setState({ activeFilter: 1 });
    } else if (e.target.id === '2') {
      sendTag(`Campagne_${date}_vivant`);
      this.setState({ activeFilter: 2 });
    }
  }

}
export default connect(() => {
  return {};
})(CampaignsFilter);

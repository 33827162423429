import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';
import ButtonPopin from '../../button-popin/ButtonPopin';

const propTypes = {
  data: React.PropTypes.object.isRequired,
  resized: React.PropTypes.func.isRequired,
};

class Tool extends BaseComponent {

  constructor(props) {
    super(props);

    // this._bind('onClickHandler', 'onImageLoaded');
  }

  render() {
    const { data, view } = this.props;
    const wrapClasses = ['tools-item', 'mosaic-tool'];
    const boxClasses = ['tools-item__wrap'];
    const aClasses = ['btn'];
    const mediaClasses = [];
    let image;
    let link;
    let videoIcon;
    let icon;
    let popinData;
    let linkUrl;
    let linkTarget;
    let linkText;
    let type;

    if (data.media) {
      switch (data.media.type) {
        case 'image':
          mediaClasses.push('tools-item__img');
          wrapClasses.push('tools-item--picture');
          icon = (<span className="zoom-wrapper" data-content="http://dondorganes.biomedecine.ddb.mathieu.localhost/sites/default/files/thumbnails/file/0DBQ001_400x600_AFF_DDO_COEUR_2020_50PC_E4.png"><a data-reactroot="" href="#" className="enlarge">Agrandir</a></span>);
          popinData = data.media.thumb.r;
          linkUrl = data.l;

          break;
        case 'video':
          wrapClasses.push('tools-item--video');
          mediaClasses.push('tools-item__img');
          icon = (<a href={ data.l } className="icon-player">
                          <span className="hidden">icon-player</span>
                      </a>);
          popinData = data.media.base_id;
          linkUrl = data.l;
          linkTarget = '_self';
          // image = link;
          break;
        case 'file':
          wrapClasses.push('tools-item--file');
          icon = (<span className="zoom-wrapper" data-content="http://dondorganes.biomedecine.ddb.mathieu.localhost/sites/default/files/thumbnails/file/0DBQ001_400x600_AFF_DDO_COEUR_2020_50PC_E4.png"><a data-reactroot="" href="#" className="enlarge">Agrandir</a></span>);
          popinData = data.st;
          mediaClasses.push('tools-item__img');
          linkUrl = data.media.file;
          linkTarget = '_blank';
          linkText = data.lt;
          break;
      }


      type = data.media.type;
      linkText = data.lt;
      linkTarget = data.lta ? '_blank' : '_self';

      if (data.doc) {
        linkUrl = data.doc.fs;
        linkTarget = '_blank';
        linkText = 'Téléchager';
      }


      image = (<div className={ mediaClasses.join(' ') }>
          <picture>
            <source
              srcSet={ `${data.media.thumb.d} 1x, ${data.media.thumb.r} 2x` }
              media="(min-width: 320px)"
            />
            <img
              src={ data.media.thumb.r }
              alt={ data.media.title }
            />
          </picture>
          <ButtonPopin type={data.media.type} content={popinData} image={`${data.media.thumb.r} 2x`} animateFrom={this.refs.image}/>
        </div>);

      link = (<ButtonPopin type={data.media.type} content={popinData} image={data.media.thumb.r} animateFrom={this.refs.image}/>);
    }

    const isBox = view === 'box';

    return (
      <div className={ wrapClasses.join(' ')} data-id={data.id} data-type={data.type.id} data-theme={data.thematique.id}>
        <div className={ boxClasses.join(' ')}>
          <div className="tools-item__type">{data.type.name}</div>
          { type === 'video' ? link : image}
          <div className="tools-item__title">{data.t}</div>
          <div className="tools-item__description">{data.d}</div>
          <a href={linkUrl} className="tools-item__cta" target={linkTarget}>{linkText}</a>
        </div>
      </div>
    );
  }
}

Tool.propTypes = propTypes;

export default Tool;

import {
  EVENTS_LOAD,
  EVENTS_LOAD_SUCCESS,
  EVENTS_UPDATE_LIST,
  EVENTS_FIND_NEAREST,
  EVENTS_NEAREST_CENTERED
} from '../constants/EventsTypes';
import Config from '../../Config';

export function requestEvents() {
  return {
    type: EVENTS_LOAD
  };
}

export function findNearest() {
  return {
    type: EVENTS_FIND_NEAREST
  };
}

export function nearestCentered() {
  return {
    type: EVENTS_NEAREST_CENTERED
  };
}


export function receiveEvents(data) {
  const currentDate = new Date();
  const positions = [];

  const events = data[0].events.map((event) => {
    const startDate = new Date(event.sd);
    const endDate = event.ed ? new Date(event.ed) : startDate;
    const getDate = (id) => Config.EVENTS.MONTH[id];
    const getDay = (id) => id.length === 1 ? `0${id}` : id;
    const position = {
      lat: Number(event.lat),
      lng: Number(event.lng)
    };
    let positionId = positions.findIndex(
      pstn => position.lat === pstn.lat && position.lng === pstn.lng
    );
    let date;

    if (positionId === -1) {
      positionId = positions.push(Object.assign({id: positions.length}, position)) - 1;
    }

    if (startDate.getFullYear() !== endDate.getFullYear()) {
      date = `du ${getDay(startDate.getDate())} ${getDate(startDate.getMonth())}
        ${startDate.getFullYear()} au ${getDay(endDate.getDate())}
        ${getDate(endDate.getMonth())} ${endDate.getFullYear()}`;
    } else if (startDate.getMonth() !== endDate.getMonth()) {
      date = `du ${getDay(startDate.getDate())} ${getDate(startDate.getMonth())}
        au ${getDay(endDate.getDate())} ${getDate(endDate.getMonth())}
        ${startDate.getFullYear()}`;
    } else if (startDate.getDate() !== endDate.getDate()) {
      date = `du ${getDay(startDate.getDate())}
        au ${getDay(endDate.getDate())} ${getDate(startDate.getMonth())}
        ${startDate.getFullYear()}`;
    } else {
      date = `le ${getDay(startDate.getDate())} ${getDate(startDate.getMonth())}
      ${startDate.getFullYear()}`;
    }

    return {
      id: parseInt(event.id, 10),
      title: event.t,
      city: event.c,
      description: event.d,
      isPassed: endDate < currentDate.setHours(0, 0, 0, 0),
      startDate,
      date,
      media: event.media,
      positionId
    };
  });

  events.sort((a, b) => {
    if (!b.isPassed && !a.isPassed) {
      return a.startDate - b.startDate;
    }

    return b.startDate - a.startDate;
  });

  return {
    type: EVENTS_LOAD_SUCCESS,
    positions,
    events
  };
}

export function fetchEvents() {
  return dispatch => {
    dispatch(requestEvents());
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const json = JSON.parse(req.responseText);
        dispatch(receiveEvents(json));
      }
    };
    req.open('GET', Config.URL.EVENTS, true);
    req.send();

    return;
  };
}

export function updateList(ids) {
  return {
    type: EVENTS_UPDATE_LIST,
    list: ids
  };
}

import React from 'react';

import BaseComponent from '../../common/components/BaseComponent';

import Media from './Media';

const propTypes = {
  data: React.PropTypes.object.isRequired,
  resized: React.PropTypes.func.isRequired
};

class Event extends BaseComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { data, resized } = this.props;
    const isPassed = data.isPassed ? <span className="overlay-text">passé</span> : null;
    const city = data.city ? `À ${data.city}, ` : '';
    const doc = data.media ? <Media data={ data.media } resized={ resized }/> : null;

    return (
      <div className="event" data-position-id={ data.positionId }>
        <h2>{ data.title }</h2>
        <span className="date">{ city }{ data.date }</span>
        { doc }
        <div className="text">
          <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
        </div>
        { isPassed }
      </div>
    );
  }
}

Event.propTypes = propTypes;

export default Event;

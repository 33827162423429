import { initAT } from './at-internet';

export default function initTac() {
  tarteaucitron.events.load = function () {
    initAT();
  };

  tarteaucitron.init({
    hashtag: '#tarteaucitron', /* Ouverture automatique du panel avec le hashtag */
    highPrivacy: true, /* désactiver le consentement implicite (en naviguant) ? */
    orientation: 'bottom', /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
    adblocker: false, /* Afficher un message si un adblocker est détecté */
    showAlertSmall: true, /* afficher le petit bandeau en bas à droite ? */
    cookieslist: true, /* Afficher la liste des cookies installés ? */
    removeCredit: false, /* supprimer le lien vers la source ? */
    showIcon: false
  });

  tarteaucitron.services.teads = {
    key: 'teads',
    type: 'analytic',
    name: 'Teads',
    needConsent: true,
    readmoreLink: '',
    uri: '',
    cookies: [],
    js: () => {
      // if (tarteaucitron.user.teads === undefined) {
      //   return;
      // }
      window.teads_e = window.teads_e || [];
      window.teads_adv_id = 11353;
      tarteaucitron.addScript('https://p.teads.tv/teads-fellow.js');
    }
  };

  ((tarteaucitron).job = tarteaucitron.job || []).push('youtube');
  (tarteaucitron.job = tarteaucitron.job || []).push('twitter');
  (tarteaucitron.job = tarteaucitron.job || []).push('facebook');
  (tarteaucitron.job = tarteaucitron.job || []).push('gplus');
  (tarteaucitron.job = tarteaucitron.job || []).push('atinternet');
  (tarteaucitron.job = tarteaucitron.job || []).push('teads');
}
